import styled from 'styled-components';
import { TextField } from '@mui/material';

export const Container = styled.div`
  width: 75%;
`;

export const KanbanHeader = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  height: 64px;
  padding: 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.secondary};
  background-color: ${({ theme }) => theme.colors.buttons.background};
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid #AEBAC1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.text.kanbanTitle};
    border-radius: 20px;
  }
`;

export const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20%;
  height: 100%;
  padding: 5px;
  background-color: transparent;
  transition: 0.2s;
  color: ${(props) => (props.isActive ? props.theme.colors.text.kanbanTitle : props.theme.colors.icons.default)};
  border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.colors.text.kanbanTitle : 'transparent')};

  button {
    background-color: transparent;
    border-radius: 50%;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    margin-left: 5px;
    transition: 0.2s;
    color: ${(props) => (props.isActive ? props.theme.colors.text.kanbanTitle : props.theme.colors.icons.default)};
  }

  &:hover {
    border-bottom: 2px solid #01374D;
    color: ${({ theme }) => theme.colors.text.kanbanTitle};
    svg {
      color: ${({ theme }) => theme.colors.text.kanbanTitle};
    }
  }
`;

export const ColumnsSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow-x: auto;
  gap: 2%;

  h1 {
    font-size: 32px;
    color: ${({ theme }) => theme.colors.text.main};
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    align-self: center;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid #AEBAC1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 20px;
  }
`;

export const KanbanMain = styled.main`
  height: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SearchCardsWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 500px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.buttons.background};
  border-radius: 5px;

  & .MuiInputBase-input {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #333;
  }


  & .MuiOutlinedInput-root {
    border-radius: 5px;

    &:hover fieldset {
      border-color: ${({ theme }) => theme.colors.secondary};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  & .MuiInputBase-input::placeholder {
    color: gray;
    opacity: 0.8;
  }
`;
