/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { Badge, Tooltip } from '@mui/material';
import { useDrag } from 'react-dnd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamIcon from '@mui/icons-material/Videocam';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { fixEncodingStrings, formatPhoneNumber } from '../../utils/services';
import { Container } from './styles';
import { ChatContext } from '../../context/ChatContext';

export default function SidebarChat({
  chat,
  isChatActive = false,
  profileImageURL = chat.profilePic
    ? chat.profilePic : 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635',
}) {
  const {
    markThisChatNotificationsAsRead,
    setIsEditingProfile,
    setSelectedImageToSend,
    setUrlImageToSend,
    kanban,
  } = useContext(ChatContext);

  const { kanbanBoards } = kanban;

  const theme = useTheme();

  const [{ isDragging }, drag] = useDrag({
    type: 'CHAT',
    item: { id: chat.id, chat, type: 'CHAT' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleSidebarChatClick = () => {
    setIsEditingProfile(false);
    markThisChatNotificationsAsRead(chat);
    setSelectedImageToSend(null);
    setUrlImageToSend([]);
  };

  const formatMessageBody = (message) => {
    const regex = /^\*(.*?)\*:\s*/;
    const match = message.match(regex);
    if (match) {
      const boldText = match[1];
      const remainingText = message.replace(regex, '');

      return fixEncodingStrings(`${boldText}: ${remainingText}`);
    }

    return fixEncodingStrings(message);
  };

  const contactName = chat.customName
  || chat.nome
  || formatPhoneNumber(chat.fromMsg);

  return (
    <Container
      ref={drag}
      onClick={handleSidebarChatClick}
      isChatActive={isChatActive}
    >
      <img src={profileImageURL} alt="Imagem de usuário do chat" />

      <div
        className="sidebar-chat-container"
      >

        <div
          className="name-and-phone-div"
        >

          <Tooltip
            placement="top"
            arrow
            title={contactName}
          >
            <h1>{contactName}</h1>
          </Tooltip>
          <Tooltip
            arrow
            placement="top"
            title={chat.nome
              ? formatPhoneNumber(chat?.fromMsg)
              : ''}
          >
            <span
              style={{
                marginLeft: '0px',
                fontWeight: chat.unreadCount > 0 ? '700' : '400',
                color: 'black',
                paddingLeft: '5px',
              }}
            >
              {
           chat.nome
             ? formatPhoneNumber(chat?.fromMsg)
             : ''
           }
            </span>
          </Tooltip>
        </div>
        <div
          className="last-message-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {
          chat.fromMe === 1 && (
            <CheckIcon style={{
              color: chat.ack >= 3
                ? theme.colors.primary
                : theme.colors.messages.sidebarUnseen,
              fontSize: '20px',
            }}
            />
          )
        }

          {
          chat.fromMe === 1
          && chat.ack >= 2 && (
          <CheckIcon style={{
            color: chat.ack >= 3
              ? theme.colors.primary
              : theme.colors.messages.sidebarUnseen,
            fontSize: '20px',
            marginLeft: '-15px',
          }}
          />
          )
          }

          {
          chat.mediaType?.startsWith('video') && (
          <div className="type-message-tag">
            <VideocamIcon
              style={{
                color: theme.colors.icons.default,
                fontSize: '20px',
              }}
            />
            <span
              style={{
                marginLeft: '0px',
                fontWeight: chat.unreadCount > 0 ? '700' : '400',
                color: 'black',
              }}
            >
              {!chat.body ? ('Vídeo') : chat.body}
            </span>
          </div>
          )
        }

          {
          chat.mediaType?.startsWith('audio') && (
            <div className="type-message-tag">
              <KeyboardVoiceIcon
                style={{
                  color: theme.colors.icons.default,
                  fontSize: '20px',
                }}
              />
              <span
                style={{
                  marginLeft: '0px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
              >
                Áudio
              </span>
            </div>
          )
        }
          {
          chat.mediaType?.startsWith('application')
          && (
            <div className="type-message-tag">
              <InsertDriveFileIcon
                style={{
                  color: theme.colors.icons.default,
                  fontSize: '20px',
                }}
              />
              <span
                style={{
                  marginLeft: '5px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
              >
                {!chat.body ? ('Documento') : chat.body}
              </span>
            </div>
          )
        }
          {
          chat.mediaType === 'template' && (
            <div className="type-message-tag">
              <SpeakerNotesIcon
                style={{
                  color: theme.colors.icons.default,
                  fontSize: '20px',
                  marginRight: '2px',
                }}
              />
              <span
                style={{
                  marginLeft: '0px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                  paddingBottom: '2px',
                }}
              >
                Template API oficial
              </span>
            </div>
          )
        }
          {
          chat.mediaType?.startsWith('image')
            && (
              <div
                className="image-message-tag"
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'center',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <CameraAltIcon style={{
                  color: theme.colors.icons.default,
                  fontSize: '20px',
                }}
                />
                <span style={{
                  marginLeft: '0px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
                >
                  {
                  chat.mediaType?.startsWith('image')
                    ? 'Foto'
                    : chat.body
                  }
                </span>
              </div>
            )
        }

          {(chat.mediaType?.startsWith('text') || !chat.mediaType || chat.mediaType === 'quotedMessage') && (
          <span
            style={{
              marginLeft: '0px',
              fontWeight: chat.unreadCount > 0 ? '700' : '400',
              color: 'black',
            }}
          >
            {!chat.mediaType?.startsWith('audio')
                && (formatMessageBody(chat.body))}

          </span>
          )}
        </div>

      </div>
      <div
        className="time-and-notifications-container"
      >
        <div className="notifications-div">
          {chat.lead_origem === 'Ura Reversa' && chat.status !== 3 && (
          <Tooltip
            arrow
            placement="top"
            title={`Lead de ${chat.lead_origem}`}
          >
            <SmartToyIcon style={{
              color: '#FF8C42',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            />
          </Tooltip>
          )}
          {chat.lead_origem === 'Agendamento' && chat.status !== 3 && (
          <Tooltip
            arrow
            placement="top"
            title={`Lead de ${chat.lead_origem}`}
          >
            <ScheduleSendIcon style={{
              color: '#FF8C42',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            />
          </Tooltip>
          )}
          <Badge
            badgeContent={chat.unreadCount}
            color="error"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '5px',
              width: '20px',
              paddingLeft: '3px',
            }}
          >
            <NotificationsIcon style={{
              display: 'flex',
              color: theme.colors.icons.default,
              alignItems: 'center',
              fontSize: '25px',
            }}
            />
          </Badge>
        </div>
        <p>
          {
         chat?.createdAt
           ? moment(chat?.createdAt).calendar()
           : ''
            }
        </p>
      </div>
    </Container>
  );
}

SidebarChat.propTypes = {
  chat: PropTypes.shape({
    body: PropTypes.string,
    fromMe: PropTypes.number.isRequired,
    ack: PropTypes.number.isRequired,
    fromMsg: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    isDeleted: PropTypes.string,
    mediaUrl: PropTypes.string,
  }).isRequired,
  isChatActive: PropTypes.bool,
};
