import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin: 10px 0;
    text-align: left;
  }

  footer {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text.kanbanTitle}
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${({ theme }) => theme.colors.text.secondary}
  }

  span {
    color: ${({ theme }) => theme.colors.text.secondary};
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 5px;
    border-radius: 6px;
    margin: 10px 0;
  }
`;
