import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.background.secondary};

  input {
    padding: 10px;
    font-size: 16px;
    border: none;
    width: 300px;
    border: 1px solid ${({ theme }) => theme.colors.borders.secondary};
    background-color: ${({ theme }) => theme.colors.background.white};
    border-radius: 6px;
  }

  div {
    display: flex;

    button {
      background-color: ${({ theme }) => theme.colors.background.white};
      border: 1px solid ${({ theme }) => theme.colors.borders.secondary};
      transition: 0.2s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.background.secondary};
      }
    }

    button:first-child {
      border-right: none;
      border-radius: 6px 0 0 6px;
    }

    button:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
`;
