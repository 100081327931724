import {
  createContext, useCallback, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useErrors } from './ErrorContext';
import { baseUrl, postRequest, patchRequest } from '../utils/services';

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [isloginLoading, setIsLoginLoading] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState({});
  const [needsPasswordReset, setNeedsPasswordReset] = useState(false);
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('');
  const [loginInfo, setLoginInfo] = useState({});

  const navigate = useNavigate();

  const { setErrorMessage } = useErrors();

  const updateRegisterInfo = useCallback((info) => {
    setRegisterInfo(info);
  }, []);

  const updateLoginInfo = useCallback((info) => {
    setLoginInfo(info);
  }, []);

  useEffect(() => {
    const user = sessionStorage.getItem('User');
    setUser(JSON.parse(user));
  }, []);

  const registerUser = useCallback(
    async (e) => {
      e.preventDefault();

      setIsRegisterLoading(true);
      setRegisterError(null);

      const response = await postRequest(
        `${baseUrl}/users/register`,
        JSON.stringify(registerInfo),
      );

      setIsRegisterLoading(false);

      if (response.error) {
        return setRegisterError(response);
      }
      sessionStorage.setItem('User', JSON.stringify(response));
      setUser(null);
      navigate('/');
    },
    [setUser, registerInfo],
  );

  const loginUser = useCallback(
    async (e, recaptchaToken, captchaRef) => {
      e.preventDefault();

      if (!recaptchaToken) {
        setLoginInfo({
          email: '',
          password: '',
        });
        return setErrorMessage('Por favor, complete o reCAPTCHA para prosseguir.');
      }

      setIsLoginLoading(true);

      try {
        const response = await postRequest(
          `${baseUrl}/users/login`,
          JSON.stringify({
            ...loginInfo,
            recaptchaToken,
          }),
        );

        setIsLoginLoading(false);

        if (response.error) {
          setLoginInfo({
            email: '',
            password: '',
          });

          if (captchaRef?.current) {
            captchaRef.current.reset();
          }

          return setErrorMessage(response.message);
        }

        if (response.needsPasswordReset) {
          setUser(response);
          return setNeedsPasswordReset(true);
        }

        const userSession = JSON.stringify(response);
        sessionStorage.setItem('User', userSession);
        localStorage.setItem('tempSession', userSession);

        setUser(response);

        await patchRequest(
          `${baseUrl}/users/online`,
          JSON.stringify({ userId: response.id }),
        );
      } catch (error) {
        setIsLoginLoading(false);
        setErrorMessage(error);
      }
    },
    [loginInfo],
  );

  useEffect(() => {
    const tempSession = localStorage.getItem('tempSession');

    if (tempSession) {
      sessionStorage.setItem('User', tempSession);
      localStorage.removeItem('tempSession');
      setUser(JSON.parse(tempSession));
    }
  }, []);

  const logoutUser = useCallback(async () => {
    sessionStorage.removeItem('User');
    localStorage.removeItem('tempSession');
    setLoginInfo({
      email: '',
      password: '',
    });
    setUser(null);
  }, [user, setUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loginUser,
        setLoginInfo,
        needsPasswordReset,
        setNeedsPasswordReset,
        isloginLoading,
        logoutUser,
        loginInfo,
        updateLoginInfo,
        registerInfo,
        updateRegisterInfo,
        registerUser,
        registerError,
        isRegisterLoading,
        passwordChangeMessage,
        setPasswordChangeMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
