import { Alert, AlertTitle } from '@mui/material';
import { useEffect } from 'react';
import Linkify from 'linkify-react';
import { Container } from './styles';
import { useErrors } from '../../context/ErrorContext';

export default function ErrorAlert() {
  const { error, clearError } = useErrors();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        clearError();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [error, clearError]);

  if (!error) return null;

  return (
    <Container>
      <Alert
        severity="error"
        variant="filled"
        onClose={clearError}
      >
        <AlertTitle style={{
          fontSize: '20px',
        }}
        >
          Erro:
        </AlertTitle>
        <Linkify options={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        >
          {error}
        </Linkify>
      </Alert>
    </Container>
  );
}
