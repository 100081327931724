import styled from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 32px;
    color: ${({ theme }) => theme.colors.text.main};
    font-weight: normal;
    margin-top: 30px;
    text-align: center;
  }

  h2 {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text.main};
    font-weight: normal;
    margin-top: 20px;
    line-height: 20px;
    text-align: center;
  }

`;
