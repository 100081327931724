import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from 'styled-components';
import { Container } from './styles';

export default function NewTemplate() {
  const [newTemplate, setNewTemplate] = useState({
    name: '',
    selectedCategory: '',
    selectedLanguage: '',
    selectedHeaderType: '',
    body: '',
    footer: '',
    categories: [
      {
        name: 'Marketing',
        value: 'MARKETING',
      },
      {
        name: 'Utilidade',
        value: 'UTILITY',
      },
      {
        name: 'Transacional',
        value: 'TRANSACTIONAL',
      },
    ],
    languages: [
      {
        name: 'Português Brasileiro',
        value: 'pt_BR',
      },
      {
        name: 'Inglês',
        value: 'en_US',
      },
    ],
    headerTypes: [
      'Texto',
      'Imagem',
      'Vídeo',
      'Documento',
    ],
    buttons: [],
    buttonTypes: [
      {
        name: 'Resposta rápida',
        value: 'Quick Reply',
      },
      {
        name: 'URL',
        value: 'URL',
      },
      {
        name: 'Telefone',
        value: 'Phone Number',
      },
    ],
  });

  const theme = useTheme();

  const handleNewTemplateNameChange = (e) => {
    const { value } = e.target;
    const regex = /[^a-z_]/g;

    const nameWithoutSpaces = value.replace(' ', '_');
    const sanitizedName = nameWithoutSpaces.replace(regex, '').toLowerCase();

    setNewTemplate((prev) => ({
      ...prev,
      name: sanitizedName,
    }));
  };

  const handleNewTemplateOnChange = (e) => {
    const { name, value } = e.target;

    setNewTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNewTemplateButtonOnChange = (e, buttonId) => {
    const { name, value } = e.target;

    setNewTemplate((prev) => ({
      ...prev,
      buttons: prev.buttons.map((button) => {
        if (button.id === buttonId) {
          return { ...button, [name]: value };
        }
        return button;
      }),
    }));
  };

  const handleAddNewTemplateButton = () => {
    const maxId = newTemplate.buttons
      .reduce((max, button) => (button.id > max
        ? button.id
        : max), 0);

    const newButton = {
      id: maxId + 1,
      title: '',
      selectedButtonType: '',
      url: '',
      phoneNumber: '',
    };

    setNewTemplate((prev) => ({
      ...prev,
      buttons: [newButton, ...prev.buttons],
    }));
  };

  const handleDeleteNewTemplateButton = (buttonId) => {
    setNewTemplate((prev) => ({
      ...prev,
      buttons: prev.buttons.filter((button) => button.id !== buttonId),
    }));
  };

  return (
    <Container>
      <div style={{
        alignSelf: 'center',
      }}
      >
        <h2
          style={{
            color: theme.colors.buttons.danger,
          }}
        >
          EM DESENVOLVIMENTO
        </h2>
      </div>
      <label htmlFor="template name">
        Nome:
        <input
          className="fullwidth-inputs-selects"
          type="text"
          value={newTemplate.name}
          onChange={handleNewTemplateNameChange}
          disabled
        />
        <span className="instruction-span">
          * Não é possível incluir números nem caracteres especiais
        </span>
      </label>
      <label htmlFor="template type">
        Tipo:
        <select
          className="fullwidth-inputs-selects"
          required
          name="selectedCategory"
          id="template type"
          value={newTemplate.selectedCategory}
          onChange={handleNewTemplateOnChange}
          disabled
        >
          <option value="default">Selecione...</option>
          {
            newTemplate.categories.map((category, index) => (
              <option
                key={index}
                value={category.value}
              >
                {category.name}
              </option>
            ))
          }

        </select>
      </label>
      <label htmlFor="template language">
        Idioma:
        <select
          className="fullwidth-inputs-selects"
          required
          name="selectedLanguage"
          id="template language"
          value={newTemplate.selectedLanguage}
          onChange={handleNewTemplateOnChange}
          disabled
        >
          <option value="default">Selecione...</option>
          {
            newTemplate.languages.map((category, index) => (
              <option
                key={index}
                value={category.value}
              >
                {category.name}
              </option>
            ))
          }

        </select>
      </label>
      <label htmlFor="template header">
        Header:
        <select
          required
          className="fullwidth-inputs-selects"
          name="selectedHeaderType"
          id="template header"
          value={newTemplate.selectedHeaderType}
          onChange={handleNewTemplateOnChange}
          disabled
        >
          <option value="default">Selecione...</option>
          {
            newTemplate.headerTypes.map((headerType, index) => (
              <option
                key={index}
                value={headerType}
              >
                {headerType}
              </option>
            ))
          }
        </select>
      </label>

      <label htmlFor="template-body">
        Body:
        <textarea
          className="fullwidth-inputs-selects"
          name="body"
          id="template-body"
          rows="5"
          value={newTemplate.body}
          onChange={handleNewTemplateOnChange}
          required
          disabled
        />
      </label>

      <label htmlFor="template-footer">
        Footer:
        <input
          className="fullwidth-inputs-selects"
          type="text"
          id="template-footer"
          name="footer"
          value={newTemplate.footer}
          onChange={handleNewTemplateOnChange}
          disabled
        />
      </label>

      Botões:
      <button
        name="template-buttons"
        id="template-buttons"
        type="button"
        aria-label="Adicionar botão"
        className="add-button"
        onClick={handleAddNewTemplateButton}
        disabled
      >
        Adicionar botão
      </button>

      {
        newTemplate.buttons.map((button, index) => (
          <div className="template-button-div">
            <input
              name="title"
              type="text"
              key={`input-name-${index}`}
              value={button.title}
              onChange={(e) => handleNewTemplateButtonOnChange(e, button.id)}
              placeholder="Texto do botão"
            />
            {button.selectedButtonType === 'URL' && (
              <input
                name="url"
                type="text"
                key={`input-url-${index}`}
                value={button.url}
                onChange={(e) => handleNewTemplateButtonOnChange(e, button.id)}
                placeholder="Link"
              />
            )}
            {button.selectedButtonType === 'Phone Number' && (
              <input
                name="phoneNumber"
                type="text"
                key={`input-phone-${index}`}
                value={button.phoneNumber}
                onChange={(e) => handleNewTemplateButtonOnChange(e, button.id)}
                placeholder="Número"
              />
            )}
            <select
              name="selectedButtonType"
              required
              key={`select-button-type-${index}`}
              value={button.selectedButtonType}
              onChange={(e) => handleNewTemplateButtonOnChange(e, button.id)}
            >
              <option value="default">Selecione...</option>
              {
            newTemplate.buttonTypes.map((buttonType, index) => (
              <option
                key={index}
                value={buttonType.value}
              >
                {buttonType.name}
              </option>
            ))
          }

            </select>
            <button
              type="button"
              aria-label="Excluir botão"
              key={`delete-button-${index}`}
              onClick={() => handleDeleteNewTemplateButton(button.id)}
            >
              <DeleteIcon />
            </button>
          </div>
        ))
      }

    </Container>
  );
}
