import styled from 'styled-components';

export const StyledButton = styled.button`
    padding: 10px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.colors.buttons.hover};
    border-radius: 20px;
    margin: 0 10px 10px 0;
    transition: 0.2s ease;

    &:not(:first-child) {
      color: white;
    }

    &:hover {
      filter: brightness(0.8);
    }

`;
