import styled from 'styled-components';

export const Container = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 1px solid ${({ theme }) => theme.colors.borders.secondary};
max-height: 100%;
align-items: center;
width: 100%;
background-color: ${({ theme }) => theme.colors.buttons.background};

.transfer-ticket-button {
  margin: 0;
}

.transfer-ticket-button > svg {
  color: ${({ theme }) => theme.colors.icons.default};
}

.close-ticket-button, .transfer-ticket-button, .schedule-message-button {
  border-radius: 50%;
  padding: 8px;
  transition: 0.1s;
  background-color: ${({ theme }) => theme.colors.buttons.background};
}

.close-ticket-button > svg {
  font-size: 23px;
  color: ${({ theme }) => theme.colors.buttons.danger}
}

.close-ticket-button:hover, .transfer-ticket-button:hover, .schedule-message-button:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.ipbxNumber-span, .lead-origin-span, .department-span, .attendant-name-span {
  padding: 4px;
  font-size: 12px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.buttons.background};
  background-color: ${({ theme }) => theme.colors.secondary};
}

.ipbxNumber-span, .attendant-name-span {
  background-color: ${({ theme }) => theme.colors.secondary};
}

.lead-origin-span {
  background-color: ${({ theme }) => theme.colors.extras.originLeadChip};
}

.department-span {
  background-color: ${({ theme }) => theme.colors.text.main};
}

input {
  background-color: ${({ theme }) => theme.colors.buttons.background};
  border: none;
  border-radius: 20px;
  width: 80%;
  align-self: center;
}

.profile-chat-button {
  display: flex;
  background-color: ${({ theme }) => theme.colors.buttons.background};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 10px 15px 10px 0;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
}

.current-chat-members-group-span {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.action-chat-buttons {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-evenly;
}

label {
  margin-left: 50px;
}

h1 {
  font-size: 18px;
  font-weight: 600;
}

div {
  display: flex;
  align-items: center;
  height: auto;
}

div div {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding: 1px 0 0 2px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

`;
