/* eslint-disable no-nested-ternary */
import {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';

import { Tooltip, Popover } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import 'moment/locale/pt-br';
import he from 'he';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useTheme } from 'styled-components';
import {
  baseUrl,
  formatPhoneNumber,
  postRequest,
  deleteRequest,
  getRequest,
  patchRequest,
  getTemplatesBaseUrl,
} from '../../utils/services';

import ChatHeader from '../ChatHeader';
import NewTemplate from '../NewTemplate';
import SelectTemplateScroller from '../SelectTemplateScroller';
import ChatMessage from '../ChatMessage';
import KanbanBoards from '../KanbanBoards';

import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';

import {
  Container,
  ContainerHeader,
  ContainerChat,
  ContainerFooter,
  QuickAnswerContainer,
  NewQuickAnswerInput,
  TemplateRendererContainer,
  SelectedTemplatePreview,
} from './styles';

import ChatIntro from '../ChatIntro';
import SendMedias from '../SendMedias';
import CustomDialog from '../CustomDialog';
import PopOverMenu from '../PopOverMenu';
import AudioRecorder from '../AudioRecorder';
import useWindowSize from '../../hooks/useWindowSize';
import { useErrors } from '../../context/ErrorContext';
import QuotedMessage from '../QuotedMessage';
import TemplateRenderer from '../ChatMessage/TemplateRenderer';

export default function ChatWindow() {
  const {
    socket,
    allUsers,
    setMessagesPage,
    hasMoreMessages,
    urlImageToSend,
    quickAnswers,
    setQuickAnswers,
    setMessageToScroll,
    setMessages,
    currentChat,
    containerRef,
    handleScrollChatToBottom,
    isInitialMount,
    messages,
    setQuotedMessages,
    isMessagesLoading,
    sendMessage,
    setAudioBlobURL,
    setUrlImageToSend,
    urlVideoToSend,
    setUrlVideoToSend,
    setCurrentChat,
    fetchMessages,
    fetchInternalMessages,
    messagesPage,
    setSelectedVideoToSend,
    setSelectedImageToSend,
    selectedDocumentToSend,
    urlDocumentToSend,
    setUrlDocumentToSend,
    setSelectedDocumentToSend,
    updateUserChatFromQueueToChats,
    updateInternalChatsWithNewMessage,
    setOpenArchivedInternalChats,
    internalChats,
    setInternalChats,
    fetchMediaUrls,
    audioBlobURL,
    isRecordingAudio,
    isUploadingAudioMessage,
    isMediaUploadLoading,
    mediaUploadProgress,
    isAComment,
    setIsAComment,
    cancelAudioRecording,
    isRecipientOnline,
    setOpenTransferModal,
    officialApiTemplates,
    handleSendTemplateMessage,
    handleSetIsOfficialApiTemplateExpired,
    handleSetIsOfficialApiTemplateSended,
    setOfficialApiTemplates,
    kanban,
  } = useContext(ChatContext);

  moment.locale('pt-br');

  const [isEmojiMenuVisible, setIsEmojiMenuVisible] = useState(false);
  const [isChatFullyBottom, setIsChatFullyBottom] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [templateAnchorEl, setTemplateAnchorEl] = useState(null);
  const [newQuickAnswer, setNewQuickAnswer] = useState({
    title: '',
    answer: '',
    textToSearch: '',
  });
  const [messageEdit, setMessageEdit] = useState({
    message: {},
    isEditing: false,
  });
  const [messageToReply, setMessageToReply] = useState({
    message: {},
    isReplying: false,
  });

  const { setErrorMessage } = useErrors();

  const {
    showQuickAnswers,
    isAddingNewAnswer,
    quickAnswersData,
    messageIdToQuickAnswer,
    messagesWithMediaUrl,
    openClearAllQuickAnswers,
    openClearSelectedQuickAnswer,
    selectedQuickAnswerToClear,
  } = quickAnswers;

  const theme = useTheme();

  const { showKanbanSection } = kanban;

  const { user } = useContext(AuthContext);

  const { width, height } = useWindowSize();

  const isNotDesktop = width <= 1028;

  const prevChatRef = useRef(currentChat);

  const container = containerRef.current;

  const toggleIsAComment = () => {
    setIsAComment((prevState) => !prevState);
  };

  const handleOpenMessageEdit = (message) => {
    setTextMessage(message.body);
    setMessageEdit({
      message,
      isEditing: true,
    });
  };

  const handleCloseMessageEdit = () => {
    setMessageEdit({
      message: {},
      isEditing: false,
    });
  };

  const handleOpenMessageToReply = (message) => {
    setMessageToReply({
      message,
      isReplying: true,
    });
  };

  const handleCloseMessageToReply = () => {
    setMessageToReply({
      message: {},
      isReplying: false,
    });
  };

  const handleMediaByType = async (mediaType, selectedQuickAnswer) => {
    switch (true) {
      case mediaType.startsWith('audio'):
        return setAudioBlobURL(selectedQuickAnswer.mediaUrl);

      case mediaType.startsWith('image'):
        return setUrlImageToSend([selectedQuickAnswer.mediaUrl]);

      case mediaType.startsWith('video'):
        return setUrlVideoToSend([selectedQuickAnswer.mediaUrl]);

      case mediaType.startsWith('application') || mediaType.startsWith('document') || mediaType.startsWith('text'):
        setSelectedDocumentToSend([{
          type: mediaType,
        }]);
        return setUrlDocumentToSend([selectedQuickAnswer.mediaUrl]);

      default:
        return null;
    }
  };

  const handleSelectQuickAnswer = async (quickAnswerId) => {
    const selectedQuickAnswer = quickAnswersData
      .find((quickAnswer) => quickAnswer
        .id === quickAnswerId);

    if (selectedQuickAnswer.mediaType) {
      const selectedQuickAnswerMedia = await fetchMediaUrls({ ...selectedQuickAnswer });

      await handleMediaByType(selectedQuickAnswer
        .mediaType, selectedQuickAnswerMedia);
    }

    setTextMessage(he.decode(selectedQuickAnswer.body));
  };

  const handleOpenClearAllQuickAnswers = () => {
    setQuickAnswers((prevQuickAnswers) => ({
      ...prevQuickAnswers,
      openClearAllQuickAnswers: true,
    }));
  };

  const handleCloseClearAllQuickAnswers = () => {
    setQuickAnswers((prevQuickAnswers) => ({
      ...prevQuickAnswers,
      openClearAllQuickAnswers: false,
    }));
  };

  const handleOpenClearSelectedQuickAnswer = (answer) => {
    setQuickAnswers((prevQuickAnswers) => ({
      ...prevQuickAnswers,
      openClearSelectedQuickAnswer: true,
      selectedQuickAnswerToClear: answer,
    }));
  };

  const handleCloseClearSelectedQuickAnswer = () => {
    setQuickAnswers((prevQuickAnswers) => ({
      ...prevQuickAnswers,
      openClearSelectedQuickAnswer: false,
    }));
  };

  const handleConfirmClearAllQuickAnswers = async () => {
    const response = await deleteRequest(`${baseUrl}/messages/deleteQuickAnswersByUser/${user.id}`);

    if (response?.error) {
      return setErrorMessage('Erro ao limpar todas respostas rápidas. Entre em contato com o suporte');
    }

    setQuickAnswers({
      ...quickAnswers,
      quickAnswersData: [],
    });

    handleCloseClearAllQuickAnswers();
  };

  const handleOnConfirmClearQuickAnswerById = async () => {
    const response = await deleteRequest(`${baseUrl}/messages/deleteQuickAnswerById/${selectedQuickAnswerToClear.id}`);

    if (response?.error) {
      return setErrorMessage('Erro ao limpar resposta rápida. Entre em contato com o suporte');
    }

    setQuickAnswers(
      {
        ...quickAnswers,
        quickAnswersData: quickAnswersData
          .filter((quickAnswer) => quickAnswer.id !== selectedQuickAnswerToClear.id),
      },
    );

    handleCloseClearSelectedQuickAnswer();
  };

  const handleIsAddingNewAnswer = () => {
    setQuickAnswers({
      ...quickAnswers,
      isAddingNewAnswer: true,
    });
  };

  const handleBackToMainQuickAnswers = () => {
    setQuickAnswers({
      ...quickAnswers,
      isAddingNewAnswer: false,
    });

    setNewQuickAnswer({
      title: '',
      answer: '',
      textToSearch: '',
    });
  };

  const handleNewQuickAnswerTitleChange = (e) => {
    const { value } = e.target;

    setNewQuickAnswer({
      ...newQuickAnswer,
      title: value,
    });
  };

  const handleOnNewQuickAnswerChange = (e) => {
    const { value } = e.target;

    setNewQuickAnswer({
      ...newQuickAnswer,
      answer: value,
    });
  };

  const handleSearchQuickAnswersChange = (e) => {
    const { value } = e.target;

    setNewQuickAnswer({
      ...newQuickAnswer,
      textToSearch: value,
    });
  };

  const handleOnConfirmQuickAnswerViaPopover = async () => {
    const encodedBody = he.encode(newQuickAnswer.answer, {
      useNamedReferences: true,
      decimal: true,
      allowUnsafeSymbols: true,
    });

    const payload = {
      id_usuario: user.id,
      titleMsg: newQuickAnswer.title,
      body: encodedBody,
      mediaType: null,
      mediaUrl: null,
      ambiente: user.ambiente,
    };

    const response = await postRequest(`${baseUrl}/messages/createQuickAnswer/${user.id}`, JSON.stringify(payload));

    if (response.error) {
      return setErrorMessage(response.error);
    }

    setQuickAnswers({
      ...quickAnswers,
      isAddingNewAnswer: false,
      quickAnswersData: [...quickAnswersData, {
        id: response,
        id_usuario: user.id,
        createdAt: Date.now(),
        titleMsg: newQuickAnswer.title,
        body: newQuickAnswer.answer,
        mediaType: null,
        mediaUrl: null,
      }],
    });

    setNewQuickAnswer({
      title: '',
      answer: '',
      textToSearch: '',
    });
  };

  const handleOnConfirmQuickAnswerViaMsg = async () => {
    const removeAttendantName = (body) => {
      const regex = /^\*[^*]+\*:\s*/;

      const modifiedBody = body.replace(regex, '');

      return modifiedBody;
    };

    const messageToTurnIntoQuickAnswer = messagesWithMediaUrl.find((msg) => {
      return msg.idMsg === messageIdToQuickAnswer;
    });

    if (!messageToTurnIntoQuickAnswer) return;

    const body = removeAttendantName(messageToTurnIntoQuickAnswer.body);

    let quickAnswerPayload = {};

    if (messageToTurnIntoQuickAnswer.mediaType || messageToTurnIntoQuickAnswer.mediaUrl) {
      quickAnswerPayload = await getRequest(`${baseUrl}/messages/${messageToTurnIntoQuickAnswer.idMsg}`);

      if (quickAnswerPayload.error) {
        return setErrorMessage('Erro ao salvar resposta rápida, entre em contato com o suporte.');
      }
    }
    const mediaType = quickAnswerPayload.mediaType || null;
    const mediaUrl = quickAnswerPayload.mediaUrl || null;

    const payload = {
      id_usuario: user.id,
      titleMsg: newQuickAnswer.title,
      body,
      mediaType,
      mediaUrl,
      ambiente: user.ambiente,
    };

    const response = await postRequest(`${baseUrl}/messages/createQuickAnswer/${user.id}`, JSON.stringify(payload));

    if (response.error) {
      return setErrorMessage(response.error);
    }

    setQuickAnswers({
      ...quickAnswers,
      messageIdToQuickAnswer: '',
      showQuickAnswers: false,
      isAddingNewAnswer: false,
      quickAnswersData: [...quickAnswersData, {
        id: response,
        id_usuario: user.id,
        createdAt: Date.now(),
        titleMsg: newQuickAnswer.title,
        body,
        mediaType,
        mediaUrl,
      }],
    });

    setNewQuickAnswer({
      title: '',
      answer: '',
      textToSearch: '',
    });
  };

  const handleCloseQuickAnswer = () => {
    setQuickAnswers({
      ...quickAnswers,
      showQuickAnswers: false,
    });
  };

  const getMediaTypeIcon = (mediaType) => {
    if (!mediaType) return null;

    switch (true) {
      case mediaType.startsWith('image'):
        return <ImageOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      case mediaType.startsWith('video'):
        return <VideocamOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      case mediaType.startsWith('audio'):
        return <MicOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      case mediaType.startsWith('document'):
      case mediaType.startsWith('text'):
      case mediaType.startsWith('application'):
        return <InsertDriveFileOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      default:
        return null;
    }
  };

  const handleScroll = () => {
    if (container) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = container;

      setIsChatFullyBottom(scrollTop + clientHeight === scrollHeight);

      if (!isMessagesLoading && scrollTop < 20 && hasMoreMessages) {
        const prevScrollHeight = container.scrollHeight / 3;
        setMessagesPage((prevPage) => prevPage + 1);
        container.scrollTop += prevScrollHeight;
      }
    }
  };

  const handleCheckIfCurrentChatIsFromOfficialApi = () => {
    const anyMessageIsFromOfficialApi = messages.some((msg) => msg.api === 1);

    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isCurrentChatFromOfficialApi: anyMessageIsFromOfficialApi,
    }));
  };

  useEffect(() => {
    if ((isInitialMount?.current
      && messages?.length > 0)
    ) {
      handleScrollChatToBottom();
      isInitialMount.current = false;
      handleSetIsOfficialApiTemplateExpired();
      handleCheckIfCurrentChatIsFromOfficialApi();
      handleSetIsOfficialApiTemplateSended();
    }

    if (prevChatRef.current !== currentChat) {
      isInitialMount.current = true;
      prevChatRef.current = currentChat;
    }
  }, [
    handleScroll,
    messages,
    currentChat,
    prevChatRef,
    isInitialMount,
    isMessagesLoading,
    hasMoreMessages,
    setMessagesPage]);

  const textareaRef = useRef(null);
  const containerFooterRef = useRef(null);

  const handleResizeTextarea = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      const { scrollHeight } = textarea;
      const maxHeight = 150;

      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handlePaste = (event) => {
    const clipboardItem = event.clipboardData.items[0];

    if (clipboardItem && clipboardItem.type.startsWith('image/')) {
      const blob = clipboardItem.getAsFile();

      if (blob) {
        setSelectedImageToSend([blob]);
      }

      event.preventDefault();
    }
  };

  const handleDragOver = (e) => {
    const { dataTransfer } = e;

    e.preventDefault();
    dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      const file = files[0];

      if (file.type.startsWith('image/')) {
        setSelectedImageToSend(files);
      } else if (file.type.startsWith('video/')) {
        setSelectedVideoToSend(files);
      } else {
        setSelectedDocumentToSend(files);
      }
    }
  };

  const toggleEmojiMenu = () => {
    setIsEmojiMenuVisible((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    setTextMessage((prevText) => prevText + emojiObject.emoji);
  };

  const handleOpenTransferModal = () => {
    setOpenTransferModal(true);
  };

  const handleChange = (event) => {
    setTextMessage(event.target.value);
  };

  const sendMediaMessages = async (textMessage, currentChat, setTextMessage, mediaUrls) => {
    if (mediaUrls.length > 0) {
      Promise.all(
        mediaUrls.map(async (url) => {
          await sendMessage(
            textMessage,
            currentChat?.sender,
            currentChat?.fromMsg,
            setTextMessage,
            url,
          );
        }),
      );
    }
  };

  const sendInternalMessage = async () => {
    const bodyMessage = currentChat.is_group === 1 ? `*${user.name}*:\n ${textMessage}` : textMessage;

    const encodedBody = he.encode(bodyMessage, {
      useNamedReferences: true,
      decimal: true,
      allowUnsafeSymbols: true,
    });

    const handleMediaPayload = async (mediaType, mediaUrls) => {
      if (Array.isArray(mediaUrls) && mediaUrls.length > 0) {
        await Promise.all(
          mediaUrls.map(async (mediaUrl) => {
            const payload = {
              body: encodedBody,
              mediaType,
              mediaUrl: mediaUrl.presignedUrl.key,
              createdAt: Date.now(),
              updatedAt: Date.now(),
              senderId: user.id,
              chatId: currentChat.id,
              isEdited: 0,
              isReaded: 0,
              isDeleted: 0,
              quotedMsgId: messageToReply.message.id || null,
            };

            const newMessageId = await postRequest(`${baseUrl}/messages/createInternalMessage`, JSON.stringify(payload));

            if (payload.quotedMsgId) {
              const quotedMessageResponse = await getRequest(`${baseUrl}/messages/internalMessage/${payload.quotedMsgId}`);

              setQuotedMessages((prevQuotedMessages) => [...prevQuotedMessages,
                quotedMessageResponse]);
            }

            const newMessage = {
              ...payload,
              mediaUrl: mediaUrl.apacheUrl,
              id: newMessageId,
            };

            if (currentChat?.is_internal_chat === 1
              && currentChat?.arquivado === 1) {
              setOpenArchivedInternalChats(false);
            }

            setMessages((prev) => [newMessage, ...prev]);
            socket.emit('sendInternalMessage', payload, currentChat, user.id);
            const updatedInternalChats = await updateInternalChatsWithNewMessage(
              internalChats,
              newMessage,
            );
            setInternalChats(updatedInternalChats);
          }),
        );
      }
    };

    try {
      if (audioBlobURL) {
        const audioPayload = {
          body: encodedBody,
          mediaType: 'audio/webm',
          mediaUrl: audioBlobURL.presignedUrl.key,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          senderId: user.id,
          chatId: currentChat.id,
          isReaded: 0,
          isEdited: 0,
          isDeleted: 0,
          quotedMsgId: messageToReply.message.id || null,
        };

        if (audioPayload.quotedMsgId) {
          const quotedMessageResponse = await getRequest(`${baseUrl}/messages/internalMessage/${audioPayload.quotedMsgId}`);

          setQuotedMessages((prevQuotedMessages) => [...prevQuotedMessages,
            quotedMessageResponse]);
        }

        const newMessageId = await postRequest(`${baseUrl}/messages/createInternalMessage`, JSON.stringify(audioPayload));

        const newMessage = {
          ...audioPayload,
          mediaUrl: audioBlobURL.apacheUrl,
          id: newMessageId,
        };

        if (currentChat?.is_internal_chat === 1
          && currentChat?.arquivado === 1) {
          setOpenArchivedInternalChats(false);
        }

        setMessages((prev) => [newMessage, ...prev]);
        socket.emit('sendInternalMessage', audioPayload, currentChat, user.id);
        const updatedInternalChats = await updateInternalChatsWithNewMessage(
          internalChats,
          audioPayload,
        );
        setInternalChats(updatedInternalChats);
      }

      await handleMediaPayload('image/jpeg', urlImageToSend);
      await handleMediaPayload('video/mp4', urlVideoToSend);

      if (urlDocumentToSend.length > 0) {
        const audioExtensions = ['.mp3', '.ogg', '.mpeg', '.webm', '.wav', '.WAV'];

        await Promise.all(
          urlDocumentToSend.map(async (file) => {
            const isAudio = audioExtensions.some((ext) => file.presignedUrl.key.endsWith(ext));
            const mimeType = isAudio ? 'audio/webm' : 'application/pdf';
            await handleMediaPayload(mimeType, [file]);
          }),
        );
      }

      if (!audioBlobURL
      && urlImageToSend.length === 0
      && urlVideoToSend.length === 0
      && urlDocumentToSend.length === 0) {
        const textPayload = {
          body: encodedBody,
          mediaType: '',
          mediaUrl: null,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          senderId: user.id,
          chatId: currentChat.id,
          isReaded: 0,
          isEdited: 0,
          isDeleted: 0,
          quotedMsgId: messageToReply.message.id || null,
        };

        if (textPayload.quotedMsgId) {
          const quotedMessageResponse = await getRequest(`${baseUrl}/messages/internalMessage/${textPayload.quotedMsgId}`);

          setQuotedMessages((prevQuotedMessages) => [...prevQuotedMessages,
            quotedMessageResponse]);
        }

        const newMessageId = await postRequest(`${baseUrl}/messages/createInternalMessage`, JSON.stringify(textPayload));

        const newMessage = {
          ...textPayload,
          id: newMessageId,
        };

        if (currentChat?.is_internal_chat === 1
          && currentChat?.arquivado === 1) {
          setOpenArchivedInternalChats(false);
        }

        setMessages((prev) => [newMessage, ...prev]);
        socket.emit('sendInternalMessage', newMessage, currentChat, user.id);
        const updatedInternalChats = await updateInternalChatsWithNewMessage(
          internalChats,
          newMessage,
        );
        setInternalChats(updatedInternalChats);
      }

      setUrlImageToSend([]);
      setSelectedImageToSend(null);
      setUrlVideoToSend([]);
      setSelectedVideoToSend(null);
      setUrlDocumentToSend([]);
      setSelectedDocumentToSend(null);
      setAudioBlobURL(null);
      cancelAudioRecording();
      setMessageEdit({
        message: {},
        isEditing: false,
      });
      setMessageToReply({
        message: {},
        isReplying: false,
      });

      setTimeout(handleScrollChatToBottom, 500);
    } catch (error) {
      setErrorMessage('Não foi possível enviar mensagem! Entre em contato com o suporte');
    }
  };

  const handleSaveMessageEdit = async () => {
    try {
      if (!messageEdit.message.id) return;

      const encodedBody = he.encode(textMessage, {
        useNamedReferences: true,
        decimal: true,
        allowUnsafeSymbols: true,
      });

      const payload = {
        id: messageEdit.message.id,
        body: encodedBody,
      };

      await patchRequest(
        `${baseUrl}/messages/updateInternalMessageBody`,
        JSON.stringify(payload),
      );

      const updatedMessage = {
        ...messageEdit.message,
        body: payload.body,
        isEdited: 1,
      };

      setMessages((prevMessages) => prevMessages
        .map((msg) => (msg.id === messageEdit.message.id
          ? { ...msg, body: payload.body, isEdited: 1 }
          : msg)));

      socket.emit('editedMessageBody', updatedMessage, user.ambiente, user.id);
    } catch (error) {
      setErrorMessage('Não foi possível salvar alterações! Entre em contato com o suporte');
    } finally {
      setTextMessage('');
      handleCloseMessageEdit();
    }
  };

  const handleSendMessage = async () => {
    if (messageEdit.isEditing) {
      handleSaveMessageEdit();
      return;
    }

    if (!isAComment && !currentChat.is_internal_chat) {
      updateUserChatFromQueueToChats(currentChat);
    }

    if (currentChat.is_internal_chat === 1) {
      sendInternalMessage();
      setTextMessage('');
      return;
    }

    const textMessageWithName = `*${user.name}*:\n ${textMessage}`;

    if (isAComment) {
      const commentPayload = {
        sender: currentChat.sender,
        fromMsg: currentChat.fromMsg,
        ambiente: user.ambiente,
        body: textMessageWithName,
        ack: 0,
        mediaType: 'text/notification',
        fromMe: 3,
        isDeleted: null,
        setorId: currentChat.setor_id,
        userId: currentChat.usuario_id,
        createdAt: Date.now(),
        api: officialApiTemplates.isCurrentChatFromOfficialApi ? 1 : 0,
      };

      const response = await postRequest(`${baseUrl}/chats/insertNotification`, JSON.stringify(commentPayload));

      if (response.error) {
        return setErrorMessage('Erro ao adicionar comentário! Entre em contato com o suporte.');
      }

      socket.emit('sendComment', commentPayload, user.ambiente);

      setTextMessage('');
      return;
    }

    const sendMedia = async (mediaUrl) => {
      if (mediaUrl?.length > 0) {
        await sendMediaMessages(textMessage, currentChat, setTextMessage, mediaUrl);
      }
    };

    await Promise.all([
      sendMedia(urlImageToSend),
      sendMedia(urlVideoToSend),
      sendMedia(urlDocumentToSend),
    ]);

    const isAnyMediaPresent = urlImageToSend.length > 0
    || urlVideoToSend.length > 0
    || urlDocumentToSend.length > 0;

    if (!isAnyMediaPresent) {
      await sendMessage(
        textMessage,
        currentChat?.sender,
        currentChat?.fromMsg,
        setTextMessage,
        audioBlobURL || null,
      );
    }

    if (!currentChat.primeira_resposta_data) {
      const updateTicketResponse = await patchRequest(`${baseUrl}/chats/updateFirstAnswerDate`, JSON.stringify({
        ticketId: currentChat.id,
        userId: user.id,
      }));

      if (updateTicketResponse.error) {
        return setErrorMessage('Algo de errado aconteceu. Entre em contato com o suporte');
      }

      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        primeira_resposta_data: Date.now(),
      }));
    }

    setUrlImageToSend([]);
    setSelectedImageToSend(null);
    setUrlVideoToSend([]);
    setSelectedVideoToSend(null);
    setUrlDocumentToSend([]);
    setSelectedDocumentToSend(null);
    setAudioBlobURL(null);
    cancelAudioRecording();
  };

  const handleSendMessageWithKeyDown = async (event) => {
    const { key, shiftKey, target } = event;

    if (key === 'Enter' && !shiftKey) {
      handleSendMessage();
      return event.preventDefault();
    }

    if (key === 'Enter' && shiftKey) {
      event.preventDefault();
      const cursorPosition = target.selectionStart;
      const newText = `${textMessage.slice(0, cursorPosition)}\n${textMessage.slice(cursorPosition)}`;
      setTextMessage(newText);

      setTimeout(() => {
        target.selectionStart = cursorPosition + 1;
        target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const getFromMeValue = (message) => {
    if (currentChat.is_internal_chat) {
      if (!message.senderId) {
        return 2;
      }
      return message.senderId === user.id ? 1 : 0;
    }

    return message.fromMe;
  };

  const handleOpenSelectOfficialApiTemplates = async (event) => {
    try {
      setTemplateAnchorEl(event.currentTarget);

      setOfficialApiTemplates((prevTemplates) => ({
        ...prevTemplates,
        loading: true,
        open: true,
      }));

      const { sessionId } = user.senders[currentChat.sender];

      const payload = {
        token: sessionId,
      };

      const response = await postRequest(`${getTemplatesBaseUrl}`, JSON.stringify(payload));

      setOfficialApiTemplates((prevTemplates) => ({
        ...prevTemplates,
        data: response.data,
        loading: false,
      }));
    } catch (error) {
      setErrorMessage('Não foi possível abrir a seleção de templates. Entre em contato com o suporte');
    }
  };

  const handleCancelSelectedTemplateToSend = () => {
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      selectedToSend: null,
    }));
  };

  const handleCloseSelectOfficialApiTemplates = () => {
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      open: false,
    }));
  };

  const handleCloseDeleteTemplateConfirmation = () => {
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isDeletingSelectedTemplate: false,
    }));
  };

  const handleConfirmTemplateDeletion = () => {

  };

  const handleOpenNewTemplateDialog = () => {
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isCreatingNewTemplate: true,
    }));
  };

  const handleCloseNewTemplateDialog = () => {
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isCreatingNewTemplate: false,
    }));
  };

  const handleConfirmNewTemplate = () => {

  };

  const ipbxNumber = user?.senders[currentChat?.sender]?.telefone?.toString();

  const leadOrigin = currentChat?.lead_origem;

  const currentChatAttendant = allUsers.find((u) => currentChat?.usuario_id === u.id);

  const contactName = currentChat?.customName
  || currentChat?.chat_name
  || currentChat?.nome
  || formatPhoneNumber(currentChat?.fromMsg)
  || currentChat?.memberName;

  const userHasAccessToCurrentChatSender = Object.keys(user.senders).some(
    (key) => key === currentChat?.sender,
  );

  if (showKanbanSection) return <KanbanBoards />;

  if (!currentChat && !isNotDesktop) return <ChatIntro />;

  return currentChat && (
    <Container
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ContainerHeader>
        <ChatHeader
          contactName={contactName}
          isOnline={isRecipientOnline}
          isInternalChat={currentChat?.is_internal_chat || currentChat?.is_internal_chat === 1}
          ipbxNumber={formatPhoneNumber(ipbxNumber)}
          leadOrigin={leadOrigin}
          attendantName={currentChatAttendant?.name}
        />
      </ContainerHeader>

      {isMediaUploadLoading
        ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <CircularProgress variant="determinate" value={mediaUploadProgress} />
          </div>
        )
        : urlImageToSend?.length > 0
          ? (
            <SendMedias
              type="image"
              urlMediaTosend={urlImageToSend}
              setMediaToSendFunc={setUrlImageToSend}
              setSelectedMediaToSendFunc={setSelectedImageToSend}
            />
          )
          : urlVideoToSend?.length > 0
            ? (
              <SendMedias
                type="video"
                urlMediaTosend={urlVideoToSend}
                setMediaToSendFunc={setUrlVideoToSend}
                setSelectedMediaToSendFunc={setSelectedVideoToSend}
              />
            )
            : urlDocumentToSend?.length > 0
              ? (
                <SendMedias
                  type="document"
                  urlMediaTosend={urlDocumentToSend}
                  setMediaToSendFunc={setUrlDocumentToSend}
                  setSelectedMediaToSendFunc={setSelectedDocumentToSend}
                />
              ) : (
                <ContainerChat
                  ref={containerRef}
                  id="containerChatId"
                >
                  <InfiniteScroll
                    scrollableTarget="containerChatId"
                    dataLength={messages.length}
                    next={currentChat.is_internal_chat === 1
                      ? () => fetchInternalMessages(currentChat, messagesPage)
                      : () => fetchMessages(currentChat, messagesPage)}
                    hasMore={hasMoreMessages}
                    loader={(
                      <div style={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      >
                        <CircularProgress variant="indeterminate" />
                      </div>
                  )}
                    loaderStyle={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    inverse
                    onScroll={handleScroll}
                    key={`${currentChat?.id}-${messages.length}`}
                  >
                    {messages?.filter((message) => {
                      const isUserDeletedChat = (
                        message.firstId
                  === user.id
                  && message.firstIdDeleted
                      )
              || (
                message.secondId
                === user.id
                && message.secondIdDeleted
              );

                      return !isUserDeletedChat;
                    })
                      .reverse()
                      .map((message, index) => (
                        <div
                          key={index}
                          id={`message-${message.id}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'relative',
                          }}
                          ref={index === 0 ? setMessageToScroll : null}
                        >

                          <ChatMessage
                            body={message.body}
                            date={moment(message.createdAt).calendar()}
                            idMsg={currentChat.is_internal_chat
                              ? message.id
                              : message.idMsg}
                            senderId={message.sender}
                            mediaType={message.mediaType || ''}
                            mediaUrl={message.mediaUrl || ''}
                            isInternalChat={currentChat?.is_internal_chat === 1}
                            videoThumb={message.videoThumb}
                            isDeleted={message.isDeleted}
                            isRecipientOnline={isRecipientOnline}
                            reacted={message.reacted}
                            isReaded={message.ack >= 3 || message.isReaded === 1}
                            isReceived={message.ack >= 2}
                            isEditing={messageEdit.message === message.id
                              ? messageEdit.isEditing
                              : false}
                            isReplying={messageToReply.message === message.id
                              ? messageToReply.isReplying
                              : false}
                            fromMe={getFromMeValue(message)}
                            isEdited={message?.isEdited === 1}
                            isAComment={message.fromMe}
                            quotedMsgId={message.quotedMsgId}
                            openMessageEdit={() => handleOpenMessageEdit(message)}
                            openMessageToReply={() => handleOpenMessageToReply(message)}
                          />
                        </div>
                      ))}

                  </InfiniteScroll>

                </ContainerChat>

              )}
      {messageEdit.isEditing && currentChat?.is_internal_chat === 1
                && (
                <QuotedMessage
                  quotedMessage={messageEdit.message}
                  currentChat={currentChat}
                  onClose={handleCloseMessageEdit}
                  isEditing={messageEdit.isEditing}
                  isReplying={messageToReply.isReplying}
                />
                )}
      {
        messageToReply.isReplying && currentChat?.is_internal_chat === 1 && (
          <QuotedMessage
            quotedMessage={messageToReply.message}
            currentChat={currentChat}
            onClose={handleCloseMessageToReply}
            isEditing={messageToReply.isEditing}
            isReplying={messageToReply.isReplying}
          />
        )
      }
      <ContainerFooter ref={containerFooterRef}>
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          height="400px"
          searchPlaceHolder="Procurar"
          open={isEmojiMenuVisible}
          style={{
            position: 'absolute',
            bottom: '45px',
            zIndex: '1',
          }}
        />

        <div className="messages-input-container">
          <button
            aria-label="Adicionar comentário"
            type="button"
            style={{
              marginLeft: '15px',
              paddingTop: '5px',
              backgroundColor: 'transparent',
            }}
            onClick={toggleIsAComment}
          >
            {!audioBlobURL
            && !isRecordingAudio
            && !isUploadingAudioMessage
            && !currentChat.is_internal_chat
            && (
              isAComment ? (
                <Tooltip
                  arrow
                  placement="top"
                  title="Cancelar comentário"
                >
                  <CloseIcon style={{
                    color: theme,
                    marginRight: '10px',
                    fontSize: '27px',
                  }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  placement="top"
                  title="Adicionar comentário"
                >
                  <TextsmsOutlinedIcon style={{
                    color: theme.colors.icons.default,
                    marginRight: '10px',
                    fontSize: '27px',
                  }}
                  />
                </Tooltip>
              )
            )}

          </button>
          {officialApiTemplates.isOfficialApiMessageExpired
          && !(currentChat.is_internal_chat === 1) ? (
            <div className="no-input-container">
              {officialApiTemplates?.selectedToSend
                ? (
                  <SelectedTemplatePreview>
                    <Tooltip
                      arrow
                      placement="top"
                      title="Cancelar template selecionado"
                    >
                      <button
                        aria-label="Cancelar template selecionado"
                        type="button"
                        onClick={handleCancelSelectedTemplateToSend}
                      >
                        <CloseIcon />
                      </button>
                    </Tooltip>
                    <TemplateRenderer
                      message={officialApiTemplates?.selectedToSend?.components}
                    />
                  </SelectedTemplatePreview>
                ) : officialApiTemplates.isOfficialApiMessageExpired
                && officialApiTemplates.isOfficialApiTemplateSended
                  ? (
                    <h5>Template enviado! Agora é só aguardar o cliente responder. 😉</h5>
                  )
                  : (
                    <h5>
                      Esse chamado segue as diretrizes da API Oficial do WhatsApp.
                      Selecione o template que deseja enviar
                      no botão ao lado para iniciar uma nova tratativa.
                    </h5>
                  )}
              <Tooltip
                arrow
                placement="top"
                title={officialApiTemplates?.selectedToSend
                  ? 'Enviar template'
                  : 'Selecionar template'}
              >
                <button
                  type="button"
                  className="select-template-button"
                  aria-label={officialApiTemplates?.selectedToSend
                    ? 'Enviar template'
                    : 'Selecionar template'}
                  onClick={
                    officialApiTemplates?.selectedToSend
                      ? handleSendTemplateMessage
                      : handleOpenSelectOfficialApiTemplates
                    }
                >
                  {officialApiTemplates?.selectedToSend ? (
                    <SendIcon style={{
                      color: theme.colors.icons.chatMessage,
                      fontSize: '30px',
                    }}
                    />
                  ) : (
                    <SpeakerNotesIcon
                      style={{
                        color: theme.colors.icons.chatMessage,
                        fontSize: '30px',
                      }}
                    />
                  )}
                </button>
              </Tooltip>
              <Popover
                open={officialApiTemplates.open}
                anchorEl={templateAnchorEl}
                style={{
                  height: '400px',
                  width: '400px',
                }}
                onClose={handleCloseSelectOfficialApiTemplates}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <TemplateRendererContainer>
                  <SelectTemplateScroller />
                  <Tooltip
                    title="Criar novo template"
                    placement="top"
                    arrow
                  >
                    <button
                      aria-label="Criar novo template"
                      type="button"
                      className="add-new-template-button"
                      onClick={handleOpenNewTemplateDialog}
                    >
                      <AddIcon />
                    </button>
                  </Tooltip>

                  <CustomDialog
                    open={officialApiTemplates.isCreatingNewTemplate}
                    onClose={handleCloseNewTemplateDialog}
                    onConfirm={handleConfirmNewTemplate}
                    message="Criando novo template: "
                    actionName="Criar"
                    confirmButtonColor={theme.colors.secondary}
                    enableConfirmButton={false}
                  >
                    <NewTemplate />
                  </CustomDialog>

                </TemplateRendererContainer>
              </Popover>
            </div>
            ) : !userHasAccessToCurrentChatSender
          && !currentChat?.is_internal_chat === 1
              ? (
                <div className="no-input-container">
                  <h5>
                    Não é possível interagir,
                    pois o chamado em questão está associado a uma conexão
                    à qual você não tem mais acesso.
                    <br />
                    Caso seja necessário,
                    inicie uma nova conversa no menu lateral superior à esquerda.
                  </h5>
                </div>
              )
              : (
                <>
                  {
            !audioBlobURL
            && !isRecordingAudio
            && !isUploadingAudioMessage
            && (currentChat?.usuario_id === user.id
              || !currentChat?.usuario_id
              || currentChat?.status === 3
              || isAComment
            )

              ? (
                <>
                  {!currentChat.is_internal_chat && (
                    <PopOverMenu
                      openPopover={false}
                      tooltipTitle="Respostas rápidas"
                      icon={(
                        <QuickreplyOutlinedIcon
                          style={{ color: theme.colors.icons.default }}
                        />
              )}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      <QuickAnswerContainer>
                        {isAddingNewAnswer
                          ? (
                            <div className="add-new-quick-answer-div">
                              <button
                                type="button"
                                aria-label="Voltar para tela principal de mensagens rápidas"
                                className="back-to-main-quick-answers-button"
                                onClick={handleBackToMainQuickAnswers}
                              >
                                <ArrowBackIcon style={{ color: theme.colors.icons.default }} />
                              </button>
                              <input
                                placeholder="Adicione um título"
                                type="text"
                                value={newQuickAnswer.title}
                                onChange={handleNewQuickAnswerTitleChange}
                              />
                              <textarea
                                value={newQuickAnswer.answer}
                                placeholder="Adicione a nova resposta rápida"
                                onChange={handleOnNewQuickAnswerChange}
                              />
                            </div>

                          )
                          : (
                            <>
                              <div className="quick-answers-header">
                                <h4>Respostas rápidas</h4>
                                <button
                                  aria-label="Limpas todas"
                                  type="button"
                                  onClick={handleOpenClearAllQuickAnswers}
                                >
                                  Limpar todas
                                </button>
                              </div>
                              <input
                                placeholder="Pesquise por título ou mensagem"
                                type="text"
                                value={newQuickAnswer.textToSearch}
                                className="search-quick-answers-input"
                                onChange={handleSearchQuickAnswersChange}
                              />
                              <div className="answers-wrapper">

                                {
                                quickAnswersData
                                  ?.filter(({ titleMsg, body }) => [titleMsg, body]
                                    .some((text) => text
                                      .toLowerCase()
                                      .includes(newQuickAnswer?.textToSearch?.toLowerCase())))
                                  .map((answer, index) => (
                                    <div key={answer.id || index} className="quick-answers-wrapper">
                                      <button
                                        className="quick-answers-button"
                                        type="button"
                                        aria-label="Selecionar resposta rápida"
                                        onClick={() => handleSelectQuickAnswer(answer.id)}
                                      >
                                        <div
                                          className="quick-answers-wrapper-header"
                                        >
                                          <h3>
                                            {he.decode(answer.titleMsg)}
                                            {getMediaTypeIcon(answer.mediaType)}
                                          </h3>
                                          <button
                                            className="delete-quick-answer-button"
                                            type="button"
                                            aria-label="Excluir resposta rápida"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleOpenClearSelectedQuickAnswer(answer);
                                            }}
                                          >
                                            x
                                          </button>
                                        </div>
                                        <p>{he.decode(answer.body)}</p>
                                      </button>

                                    </div>
                                  ))
                              }

                              </div>
                            </>
                          )}

                        <Tooltip
                          arrow
                          title={
                            isAddingNewAnswer
                              ? 'Salvar resposta rápida'
                              : 'Adicionar nova resposta rápida'
                          }
                        >

                          <button
                            type="button"
                            aria-label={
                            isAddingNewAnswer
                              ? 'Salvar resposta rápida'
                              : 'Adicionar nova resposta rápida'
                          }
                            className="add-quick-answers-button"
                            disabled={(newQuickAnswer.title === ''
                            || newQuickAnswer.answer === '')
                            && isAddingNewAnswer}
                            onClick={isAddingNewAnswer
                              ? handleOnConfirmQuickAnswerViaPopover
                              : handleIsAddingNewAnswer}
                          >
                            {
                            isAddingNewAnswer
                              ? (
                                <SaveIcon style={{
                                  color: '#F5F5F5',
                                  fontSize: '30px',
                                }}
                                />
                              )
                              : (
                                <AddIcon style={{
                                  color: '#F5F5F5',
                                  fontSize: '30px',
                                }}
                                />
                              )
                          }

                          </button>
                        </Tooltip>
                      </QuickAnswerContainer>
                    </PopOverMenu>
                  )}
                  <button
                    type="button"
                    className="emojis-button"
                    onClick={toggleEmojiMenu}
                  >
                    {isEmojiMenuVisible
                      ? (
                        <CloseIcon style={{ fontSize: '27px' }} />
                      )
                      : (
                        <InsertEmoticonIcon style={{ fontSize: '27px' }} />
                      )}
                  </button>
                  <textarea
                    ref={textareaRef}
                    placeholder={isAComment ? 'Digite um comentário' : 'Digite uma mensagem'}
                    value={textMessage}
                    onChange={(e) => {
                      handleChange(e);
                      handleResizeTextarea();
                    }}
                    onKeyDown={handleSendMessageWithKeyDown}
                    rows={1}
                    style={{
                      width: '100%',
                      resize: 'none',
                      overflowY: 'hidden',
                      lineHeight: '20px',
                      backgroundColor: isAComment ? '#FFFF66' : '#F5F5F5',
                    }}
                    onPaste={handlePaste}
                  />

                </>
              )
              : isRecordingAudio
              || audioBlobURL
              || isUploadingAudioMessage
                ? ''
                : (
                  <div className="no-input-container">
                    <h5>Chamado com outro atendente.</h5>
                    <button
                      type="button"
                      aria-label="Transferir chamado"
                      onClick={handleOpenTransferModal}
                    >
                      Transferir chamado
                    </button>
                  </div>
                )
        }

                  {
            textMessage === ''
            && urlImageToSend.length === 0
            && !isAComment
            && !messageEdit.isEditing
            && urlVideoToSend.length === 0
            && !selectedDocumentToSend
            && (currentChat?.usuario_id === user.id
              || !currentChat?.usuario_id
              || currentChat?.status === 3)
            && (
              <AudioRecorder />
            )
          }

                  {((textMessage !== '' && !messageEdit.isEditing)
          || audioBlobURL
          || urlImageToSend.length > 0
          || urlVideoToSend.length > 0
          || selectedDocumentToSend
          || isAComment)
        && (
          <button
            type="button"
            className="send-message-button"
            onClick={handleSendMessage}
            disabled={isMessagesLoading}
            aria-label="Ícone de enviar mensagem"
            style={{
              zIndex: '999',
            }}
          >
            <SendIcon style={{
              color: '#F5F5F5',
              fontSize: '25px',
              marginLeft: '3px',
              marginBottom: '2px',
            }}
            />
          </button>
        )}

                  {
          messageEdit.isEditing && (
            <Tooltip
              arrow
              placement="top"
              title="Salvar edição"
            >
              <button
                aria-label="Salvar edição"
                type="button"
                className="save-edit-button"
                onClick={handleSaveMessageEdit}
              >
                <CheckIcon />
              </button>
            </Tooltip>
          )
        }
                </>
              )}

        </div>
        <CustomDialog
          open={openClearAllQuickAnswers}
          onClose={handleCloseClearAllQuickAnswers}
          onConfirm={handleConfirmClearAllQuickAnswers}
          message="Tem certeza que deseja limpar todas "
          contactName="respostas rápidas?"
          actionName="Limpar"
          confirmButtonColor={theme.colors.buttons.danger}
        />
        <CustomDialog
          open={openClearSelectedQuickAnswer}
          onClose={handleCloseClearSelectedQuickAnswer}
          onConfirm={handleOnConfirmClearQuickAnswerById}
          message="Tem certeza que deseja excluir a "
          contactName={`resposta "${selectedQuickAnswerToClear.titleMsg}"?`}
          actionName="Excluir"
          confirmButtonColor={theme.colors.buttons.danger}
        />
        <CustomDialog
          open={officialApiTemplates.isDeletingSelectedTemplate}
          onClose={handleCloseDeleteTemplateConfirmation}
          onConfirm={handleConfirmTemplateDeletion}
          message="Tem certeza que deseja excluir "
          contactName="template?"
          actionName="Excluir"
          confirmButtonColor={theme.colors.buttons.danger}
        />

        <CustomDialog
          open={showQuickAnswers}
          onClose={handleCloseQuickAnswer}
          onConfirm={handleOnConfirmQuickAnswerViaMsg}
          enableConfirmButton={newQuickAnswer.title !== ''}
          message="Adicione um título a resposta rápida: "
          actionName="Adicionar"
          confirmButtonColor="#014F6D"
        >
          <NewQuickAnswerInput
            value={newQuickAnswer.title}
            onChange={handleNewQuickAnswerTitleChange}
          />

        </CustomDialog>
      </ContainerFooter>
    </Container>
  );
}
