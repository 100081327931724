import styled from 'styled-components';

export const StyledButton = styled.button`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.buttons.danger};
  padding: 15px;
  color: ${({ theme }) => theme.colors.buttons.background};
  transition: 0.2s;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  .MuiTooltip-tooltip {
    font-size: 25px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
