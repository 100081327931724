import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 10px;
    border-radius: 10px;
    width: 98%;
    background-color: #F5F5F5;
    border: 1px solid ${({ theme }) => theme.colors.borders.secondary};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .left-bar {
    width: 5px;
    background-color: ${({ theme }) => theme.colors.messages.quotedSidebar};
    border-radius: 10px 0 0 10px;
    height: 100%;
  }

  .quoted-message-content {
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  }

  .quoted-message-wrapper {
    width: 98%;
    display: flex;
    flex-direction: row;
  }

  .sender-name {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.messages.quotedSidebar};
    margin-bottom: 4px;
  }

  .quoted-message-text {
    color: #333;
    font-size: 16px;
    p {
      max-height: 100px;
      overflow-y: auto;
      text-overflow: ellipsis;

      &::-webkit-scrollbar {
        width: 8px;
        height: 6px;
        border: 1px solid ${({ theme }) => theme.colors.borders.main};
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: rgba(0,0,0,0.2);
      }
    }
  }

  .error-quoted-message-text {
      display: flex;
      align-items: center;
      font-style: italic;
      font-size: 1rem;
      color: #333;
      line-height: 1.5;
      margin: 0;
      width: 100%;

      svg {
        margin-right: 5px;
        color: ${({ theme }) => theme.colors.icons.default};
      }
    }

    .time-and-confirmation-span {
    margin-top: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .media-quoted-message-box {
    width: 100%;
    margin-top: 5px;
    height: auto;
    max-height: 300px;
  }
  .message-deleted-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 10px;
  }

  .quoted-message-media-div {
    padding: 5px;
    max-width: 80%;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .quoted-media {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
    }

    .message-media-div {
    padding: 5px;
    max-width: 80%;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  }
  button {
    border-radius: 50%;
    background-color: #F5F5F5;
    margin-top: 5px;
    height: 30px;
    width: 30px;
    transition: 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
