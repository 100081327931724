import { Container } from './styles';
import teleinBanner from '../../assets/images/telein-banner.png';

export default function ChatIntro() {
  return (
    <Container>
      <img
        className="telein-banner"
        src={teleinBanner}
        alt="Logomarca Telein"
        width="250"
        height="auto"
      />

      <h1>Selecione um contato para iniciar uma conversa</h1>
      <h2>O Whatsapp conecta ao seu telefone para sincronizar suas mensagens.</h2>
    </Container>
  );
}
