import styled from 'styled-components';

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 400px;
flex-direction: column;

div {
  width: 100%;
  padding: 0 10px;
}

h1 {
  font-weight: 600;
  width: 100%;
  font-size: 18px;
}

.select-template-div {
  height: 300px;
  margin-top: 5px;
  overflow-y: auto;
}

h1:first-child {
  font-size: 20px;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.secondary};
  font-weight: 700;
}

.send-message-button {
  color: white;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 6px 8px;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  margin: 10px 10px 10px 0;
}

.cancel-selected-template-button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: end;
  margin-top: 10px;
  padding: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    filter: brightness(1.2);
  }

  svg {
    color: ${({ theme }) => theme.colors.icons.chatMessage}
  }
}

.send-message-button:hover {
  background-color: ${({ theme }) => theme.colors.secondary};
  filter: brightness(0.8);
}

p {
  margin-top: 10px;
  font-weight: 600;
}

input, textarea {
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borders.secondary};
  font-size: 16px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 6px;
  resize: none;
}

textarea {
  height: 100px;
}

img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 15px 0 5px;
  }

select {
  padding: 5px;
  margin: 5px 0;
  border: 1px solid ${({ theme }) => theme.colors.borders.secondary};
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
}

option {
  font-size: 16px;
}

.MuiCircularProgress-svg {
  color: ${({ theme }) => theme.colors.secondary};
}
`;
