/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container } from './styles';
import TemplateRenderer from '../ChatMessage/TemplateRenderer';
import { ChatContext } from '../../context/ChatContext';

export default function SelectTemplateScroller() {
  const { officialApiTemplates, setOfficialApiTemplates, showAlert } = useContext(ChatContext);

  const getCountryCode = (languageCode) => {
    return languageCode.split('_')[1];
  };

  const getFlagEmoji = (countryCode) => {
    return countryCode
      .toUpperCase()
      .split('')
      .map((char) => String.fromCodePoint(127397 + char.charCodeAt()))
      .join('');
  };

  const getFlagEmojiFromLanguageCode = (languageCode) => {
    const countryCode = getCountryCode(languageCode);
    if (!countryCode) return '🌐';
    return getFlagEmoji(countryCode);
  };

  const handleSelectTemplateToSend = (template) => {
    if (template.status === 'REJECTED') {
      showAlert('Não é possível selecionar templates rejeitados.', 'error', 5000);
      return;
    }
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      open: false,
      selectedToSend: template,
    }));
  };

  const handleOpenDeleteTemplateConfirmation = (event) => {
    event.stopPropagation();
    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isDeletingSelectedTemplate: true,
    }));
  };

  return (
    <Container>
      {officialApiTemplates.loading
        ? (
          <div className="load-template-wrapper">
            <CircularProgress variant="indeterminate" />
          </div>
        )
        : (officialApiTemplates.data.map((template, index) => (
          <button
            className={`select-template-button ${template.status.toLowerCase()}`}
            aria-label="Selecionar template"
            type="button"
            key={index}
            onClick={() => handleSelectTemplateToSend(template)}
            disabled={template.status === 'PENDING'}
          >
            <div className="select-template-header">
              <h4>
                {template.status === 'APPROVED'
                  ? 'APROVADO'
                  : template.status === 'PENDING'
                    ? 'PENDENTE'
                    : 'REJEITADO'}

              </h4>
              <Tooltip
                arrow
                placement="top"
                title="Excluir template (em desenvolvimento)"
              >
                <button
                  className="delete-template-button"
                  type="button"
                  aria-label="Excluir template"
                  onClick={handleOpenDeleteTemplateConfirmation}
                  disabled
                >
                  <DeleteIcon />
                </button>
              </Tooltip>
            </div>
            <h2>
              {template.name}
              {' '}
              {getFlagEmojiFromLanguageCode(template.language)}
            </h2>
            <TemplateRenderer message={template.components} />
          </button>
        )))}
    </Container>
  );
}
