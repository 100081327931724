import { useTheme } from 'styled-components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { CircularProgress } from '@mui/material';
import { Container } from './styles';
import { ChatContext } from '../../context/ChatContext';
import { baseUrl, debounce, getRequest } from '../../utils/services';

export default function MessageSearch() {
  const [foundedMessages, setFoundedMessages] = useState({
    data: [],
    currentIndex: 0,
  });

  const theme = useTheme();

  const {
    handleInputMessageChange,
    messages,
    searchMessageValue,
    setMessagesPage,
    messagesPage,
    hasMoreMessages,
    handleScrollChatToBottom,
    setMessages,
    getMessages,
    currentChat,
    showAlert,
    isMessagesLoading,
  } = useContext(ChatContext);

  const handleSearchMessageUp = async () => {
    if (searchMessageValue === '') return;

    if (foundedMessages.currentIndex >= foundedMessages.data.length - 1) {
      if (!hasMoreMessages) {
        showAlert('Todas mensagens da conversa atual já foram carregadas.', 'info', 5000);
        return;
      }

      const fetchedMessages = await getMessages(
        currentChat?.sender,
        currentChat?.fromMsg,
        messages.length,
        400,
      );

      setMessages((prevMessages) => {
        const uniqueMessages = fetchedMessages.filter(
          (newMsg) => !prevMessages.some((prevMsg) => prevMsg.id === newMsg.id),
        );

        const previousMessages = prevMessages.filter(
          (prevMsg) => !prevMsg.chatId,
        );
        return [...previousMessages, ...uniqueMessages];
      });

      return;
    }

    const nextIndex = foundedMessages.currentIndex + 1;

    setFoundedMessages((prev) => ({
      ...prev,
      currentIndex: nextIndex,
    }));

    setTimeout(() => {
      const nextMessage = document.getElementById(`message-${foundedMessages.data[nextIndex]?.id}`);
      if (nextMessage) {
        nextMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  };

  const handleSearchMessageDown = () => {
    if (searchMessageValue === '') return;

    if (foundedMessages.currentIndex <= 0) return;

    const previousIndex = foundedMessages.currentIndex - 1;
    setFoundedMessages((prev) => ({
      ...prev,
      currentIndex: previousIndex,
    }));

    setTimeout(() => {
      const nextMessage = document.getElementById(`message-${foundedMessages.data[previousIndex]?.id}`);
      if (nextMessage) {
        nextMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  };

  const debouncedUpdateFoundedMessagesData = useCallback(
    debounce(async () => {
      if (searchMessageValue === '') {
        setFoundedMessages({
          data: [],
          currentIndex: 0,
        });
        handleScrollChatToBottom();
        return;
      }

      const matchedMessages = messages?.filter(
        (message) => message.body.toLowerCase().includes(searchMessageValue.toLowerCase())
          && !message?.mediaType?.startsWith('audio'),
      );

      setFoundedMessages({
        data: matchedMessages,
        currentIndex: 0,
      });

      if (matchedMessages.length === 0 && hasMoreMessages) {
        const response = await getRequest(
          `${baseUrl}/messages/${currentChat.sender}/${currentChat.fromMsg}/?searchQuery=${encodeURIComponent(searchMessageValue)}`,
        );

        if (response.statusCode === 404) {
          showAlert('Nenhuma mensagem encontrada com os caracteres fornecidos.', 'error', 5000);
          return;
        }

        setTimeout(() => {
          setMessagesPage((prev) => prev + 1);
        }, 1000);
      }

      setTimeout(() => {
        const firstMessage = document.getElementById(`message-${matchedMessages[0]?.id}`);
        if (firstMessage) {
          firstMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 200);
    }, 1000),
    [searchMessageValue, messages],
  );

  useEffect(() => {
    debouncedUpdateFoundedMessagesData();
  }, [searchMessageValue, messagesPage, messages]);

  useEffect(() => {
    return () => {
      debouncedUpdateFoundedMessagesData.cancel?.();
    };
  }, [debouncedUpdateFoundedMessagesData]);

  return (
    <Container>
      <input
        onChange={handleInputMessageChange}
        type="text"
        placeholder="Procurar mensagem"
        value={searchMessageValue}
        disabled={isMessagesLoading}
      />
      <p>
        {`${foundedMessages.data.length === 0 ? '0' : foundedMessages.currentIndex + 1} de ${foundedMessages.data.length}`}
      </p>
      {
        isMessagesLoading && searchMessageValue !== '' && (
          <CircularProgress variant="indeterminate" />
        )
      }
      <div>
        <button
          type="button"
          aria-label="Procurar mensagem acima"
          onClick={handleSearchMessageUp}
          disabled={isMessagesLoading}
        >
          <ArrowUpwardIcon
            style={{ color: theme.colors.icons.default }}
          />
        </button>
        <button
          type="button"
          aria-label="Procurar mensagem abaixo"
          onClick={handleSearchMessageDown}
          disabled={isMessagesLoading}
        >
          <ArrowDownwardIcon
            style={{ color: theme.colors.icons.default }}
          />
        </button>
      </div>
    </Container>
  );
}
