import { Popover } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Popover)`
width: 100%;

  button {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: 0.2s ease;
    background-color: ${({ theme }) => theme.colors.buttons.background};

    &:hover {
      background-color: ${({ theme }) => theme.colors.buttons.hover};
    }
  }
`;
