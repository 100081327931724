import styled from 'styled-components';

export const Container = styled.div`
  max-width: 450px;
  width: auto;

  button {
  background: ${({ theme }) => theme.colors.buttons.background};
  padding: 15px 10px;
  display: flex;
  transition: 0.2s;
  width: 100%;
  align-items: center;
  justify-content: start;
  text-align: start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.secondary};
}

  h1 {
    margin-left: 10px;
  }

button:hover {
  background-color: ${({ theme }) => theme.colors.buttons.hover};
}
`;
