import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
}

h1 {
  font-size: 20px;
  min-height: 24px;
}

body {
  background: ${({ theme }) => theme.colors.background.main}
}

button {
  cursor: pointer;
  border: none;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
