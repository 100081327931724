import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import { ChatContext } from '../../../context/ChatContext';
import CustomDialog from '../../CustomDialog';
import PopOverMenu from '../../PopOverMenu';
import {
  MembersWrapper,
  MemberContent,
  MembersSection,
  AddMemberButton,
  LeaveGroupButton,
  NewMembersContainer,
} from './styles';
import { Container, EditProfileChatInput } from '../styles';
import { UserCheckbox } from '../../Sidebar/styles';
import { AuthContext } from '../../../context/AuthContext';
import { useErrors } from '../../../context/ErrorContext';
import {
  baseUrl, deleteRequest, patchRequest, postRequest,
} from '../../../utils/services';

export default function EditProfileGroupChat({
  groupImageUrl = null,
  currentChat,
}) {
  const [groupDetails, setGroupDetails] = useState({
    groupName: currentChat.chat_name || '',
    groupDescription: currentChat.chat_description || '',
    isEditingGroupName: false,
    isAddingNewMembers: false,
    selectedMembersForAdding: [],
  });

  const [memberOptions, setMemberOptions] = useState({
    openLeaveGroupDialog: false,
    openRemoveFromGroupDialog: false,
    openPromoteToAdminDialog: false,
    selectedMember: {},
  });

  const {
    openLeaveGroupDialog,
    openRemoveFromGroupDialog,
    openPromoteToAdminDialog,
    selectedMember,
  } = memberOptions;

  const {
    groupName,
    groupDescription,
    isEditingGroupName,
    isAddingNewMembers,
    selectedMembersForAdding,
  } = groupDetails;

  const {
    setIsEditingProfileGroupChat,
    allUsers,
    socket,
    setCurrentChat,
    setInternalChats,
    showAlert,
  } = useContext(ChatContext);

  const { user } = useContext(AuthContext);

  const theme = useTheme();

  const { setErrorMessage } = useErrors();

  const handleCancelEditingProfileGroupChat = () => {
    setIsEditingProfileGroupChat(false);
  };

  const handleOnChangeGroupName = (e) => {
    const { value } = e.target;
    setGroupDetails((prev) => ({
      ...prev,
      groupName: value,
    }));
  };

  const handleOnChangeChatDescription = (e) => {
    const { value } = e.target;
    setGroupDetails((prev) => ({
      ...prev,
      groupDescription: value,
    }));
  };

  const handleOpenIsEditingGroupName = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isEditingGroupName: true,
    }));
  };

  const handleCloseIsEditingGroupName = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isEditingGroupName: false,
    }));
  };

  const handleSaveChatChanges = async () => {
    try {
      const payload = {
        chatId: currentChat.id,
        chat_name: groupName,
        chat_description: groupDescription,
      };
      patchRequest(`${baseUrl}/chats/updateGroupChatInformations`, JSON.stringify(payload));

      setCurrentChat((prev) => {
        return {
          ...prev,
          chat_name: groupName,
          chat_description: groupDescription,
        };
      });

      setInternalChats((prevInternalChats) => prevInternalChats.map((chat) => {
        if (chat.id === currentChat.id) {
          return { ...chat, chat_name: groupName, chat_description: groupDescription };
        }
        return chat;
      }));

      setGroupDetails((prev) => ({
        ...prev,
        isEditingGroupName: false,
      }));

      showAlert('Informações do grupo atualizadas com sucesso', 'success', 5000);
    } catch (error) {
      setErrorMessage('Não foi possível salvar alterações! Tente novamente mais tarde.');
    }
  };

  const handleOpenAddingNewMembers = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isAddingNewMembers: true,
    }));
  };

  const handleCloseAddingNewMembers = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isAddingNewMembers: false,
    }));
  };

  const handleOpenRemoveFromGroup = (memberName, memberId, isAdm) => {
    setMemberOptions((prev) => ({
      ...prev,
      openRemoveFromGroupDialog: true,
      selectedMember: {
        memberName,
        memberId,
        isAdm,
      },
    }));
  };

  const handleCloseRemoveFromGroup = () => {
    setMemberOptions((prev) => ({
      ...prev,
      openRemoveFromGroupDialog: false,
    }));
  };

  const handleOpenLeaveGroup = (memberId) => {
    setMemberOptions((prev) => ({
      ...prev,
      openLeaveGroupDialog: true,
      selectedMember: {
        memberName: user.name,
        memberId,
      },
    }));
  };

  const HandleCloseLeaveGroup = () => {
    setMemberOptions((prev) => ({
      ...prev,
      openLeaveGroupDialog: false,
    }));
  };

  const handleOpenPromoteToAdmin = (memberName, memberId, isAdm) => {
    setMemberOptions((prev) => ({
      ...prev,
      openPromoteToAdminDialog: true,
      selectedMember: {
        memberName,
        memberId,
        isAdm,
      },
    }));
  };

  const handleClosePromoteToAdmin = () => {
    setMemberOptions((prev) => ({
      ...prev,
      openPromoteToAdminDialog: false,
    }));
  };

  const handleConfirmPromoteToAdmin = async () => {
    try {
      const payload = {
        chatId: currentChat.id,
        userId: selectedMember.memberId,
      };

      await patchRequest(`${baseUrl}/chats/promoteToAdmin`, JSON.stringify(payload));

      setCurrentChat((prev) => {
        return {
          ...prev,
          members: prev.members.map((member) => {
            if (member.memberId === selectedMember.memberId) {
              return { ...member, isAdm: 1 };
            }
            return member;
          }),
        };
      });

      showAlert('Usuário promovido a Admin com sucesso', 'success', 5000);
    } catch (error) {
      setErrorMessage(`Não foi possível promover ${selectedMember.memberName} a Admin! Tente novamente.`);
    }
  };

  const handleConfirmRevokeAdminRole = async () => {
    try {
      const payload = {
        chatId: currentChat.id,
        userId: selectedMember.memberId,
      };

      await patchRequest(`${baseUrl}/chats/revokeAdminRole`, JSON.stringify(payload));

      setCurrentChat((prev) => {
        return {
          ...prev,
          members: prev.members.map((member) => {
            if (member.memberId === selectedMember.memberId) {
              return { ...member, isAdm: 0 };
            }
            return member;
          }),
        };
      });

      showAlert('Cargo revogado com sucesso', 'success', 5000);
    } catch (error) {
      setErrorMessage(`Não foi possível revogar o cargo de ${selectedMember.memberName}! Tente novamente.`);
    }
  };

  const handleConfirmLeaveGroup = async () => {
    try {
      await deleteRequest(`${baseUrl}/chats/removeMemberFromInternalChat/${currentChat.id}/${user.id}`);

      setInternalChats((prevInternalChats) => prevInternalChats
        .filter((chat) => chat.id !== currentChat.id));

      const payload = {
        body: `${user.name} saiu do grupo.`,
        mediaType: 'text/notification',
        mediaUrl: null,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        senderId: null,
        chatId: currentChat.id,
        isEdited: 0,
        isReaded: 0,
        isDeleted: 0,
        quotedMsgId: null,
      };

      const newMessageId = await postRequest(`${baseUrl}/messages/createInternalMessage`, JSON.stringify(payload));

      socket.emit('sendInternalMessage', payload, currentChat, user.id);

      handleCancelEditingProfileGroupChat();
      setCurrentChat(null);
    } catch (error) {
      setErrorMessage('Não foi possível sair do grupo! Tente novamente.');
    }
  };

  const handleConfirmRemoveFromGroup = async () => {
    try {
      await deleteRequest(`${baseUrl}/chats/removeMemberFromInternalChat/${currentChat.id}/${selectedMember.memberId}`);

      setCurrentChat((prev) => {
        return {
          ...prev,
          members: prev.members.filter((member) => member.memberId !== selectedMember.memberId),
        };
      });

      const payload = {
        body: `${selectedMember.memberName} foi removido do grupo.`,
        mediaType: 'text/notification',
        mediaUrl: null,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        senderId: null,
        chatId: currentChat.id,
        isEdited: 0,
        isReaded: 0,
        isDeleted: 0,
        quotedMsgId: null,
      };

      const newMessageId = await postRequest(`${baseUrl}/messages/createInternalMessage`, JSON.stringify(payload));

      socket.emit('sendInternalMessage', payload, currentChat, user.id);

      showAlert('Usuário removido do grupo com sucesso', 'success', 5000);
    } catch (error) {
      setErrorMessage(`Não foi possível remover ${selectedMember.memberName} do grupo! Tente novamente.`);
    }
  };

  const handleConfirmAddNewMembers = async () => {
    try {
      const payload = {
        members: selectedMembersForAdding,
        chatId: currentChat.id,
      };

      await postRequest(`${baseUrl}/chats/addNewMembersForGroupChat`, JSON.stringify(payload));

      setCurrentChat((prev) => {
        return {
          ...prev,
          members: [...prev.members, ...selectedMembersForAdding],
        };
      });

      setGroupDetails((prev) => ({
        ...prev,
        selectedMembersForAdding: [],
      }));

      const notificationPromises = selectedMembersForAdding.map(async (addedMember) => {
        const notificationPayload = {
          body: `${addedMember.memberName} foi adicionado ao grupo.`,
          mediaType: 'text/notification',
          mediaUrl: null,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          senderId: null,
          chatId: currentChat.id,
          isEdited: 0,
          isReaded: 0,
          isDeleted: 0,
          quotedMsgId: null,
        };

        await postRequest(`${baseUrl}/messages/createInternalMessage`, JSON.stringify(notificationPayload));

        socket.emit('sendInternalMessage', notificationPayload, currentChat, user.id);
      });

      await Promise.all(notificationPromises);

      showAlert('Usuários adicionados ao grupo com sucesso', 'success', 5000);
    } catch (error) {
      setErrorMessage('Não foi possível adicionar membros! Entre em contato com o suporte');
    }
  };

  const handleSelectNewMembers = (userId, userName) => {
    if (selectedMembersForAdding.some((member) => member.memberId === userId)) {
      setGroupDetails((previousGroupDetails) => ({
        ...previousGroupDetails,
        selectedMembersForAdding: selectedMembersForAdding
          .filter((member) => member.memberId !== userId),
      }));
    } else {
      setGroupDetails((previousGroupDetails) => ({
        ...previousGroupDetails,
        selectedMembersForAdding: [
          ...selectedMembersForAdding, {
            memberId: userId,
            memberName: userName,
          }],
      }));
    }
  };

  const isCurrentUserAdmin = currentChat.members
    .some((member) => member.memberId === user.id
  && member.isAdm === 1);

  return (
    <Container>
      <button
        type="button"
        aria-label="cancelar edição de contato"
        onClick={handleCancelEditingProfileGroupChat}
        className="cancel-edit-contact-button"
      >
        <CloseOutlinedIcon style={{
          color: theme.colors.icons.default,
          fontSize: '30px',
        }}
        />
      </button>

      <div
        className="profile-img-wrapper"
      >

        {
        isEditingGroupName
          ? (
            <div>
              <EditProfileChatInput
                value={groupName}
                placeholder="Editar nome do contato"
                onChange={handleOnChangeGroupName}
              />
              <Tooltip
                arrow
                title="Cancelar alteração"
              >
                <button
                  type="button"
                  aria-label="Cancelar alteração no nome do contato"
                  onClick={handleCloseIsEditingGroupName}
                >
                  <CloseOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
          : (
            <div>
              <h1>{groupName}</h1>
              <Tooltip
                arrow
                title="Alterar nome do contato"
              >
                <button
                  type="button"
                  aria-label="Alterar nome do contato"
                  onClick={handleOpenIsEditingGroupName}
                >
                  <ModeEditOutlineOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
        }

      </div>
      <div className="profile-fields-wrapper">
        <h2>
          Descrição do grupo
        </h2>
        <textarea
          readOnly={!isCurrentUserAdmin}
          value={groupDescription}
          placeholder="Escreva aqui uma descrição para esse grupo."
          onChange={handleOnChangeChatDescription}
        />
        <button
          aria-label="Salvar alterações"
          className="save-changes-button"
          type="button"
          onClick={handleSaveChatChanges}
          disabled={groupName === currentChat.chat_name
            && groupDescription === currentChat.chat_description}
        >
          Salvar alterações
        </button>
      </div>
      <MembersSection>
        <h2>
          Membros (
          {currentChat.members.length}
          )
        </h2>
        <MembersWrapper>
          {currentChat.members
            .sort((a, b) => (b.memberId === user.id
              ? 1
              : 0) - (a.memberId === user.id
              ? 1
              : 0))
            .map((member, index) => (
              <MemberContent
                key={index}
                id={index}
              >
                <p>
                  {member.memberName}
                  {' '}
                  {member.memberId === user.id ? '(eu)' : ''}
                </p>
                <div
                  key={index}
                >
                  {member.isAdm === 1 ? 'Admin' : ''}
                  {isCurrentUserAdmin && (
                  <PopOverMenu
                    tooltipTitle="Opções de membro"
                    icon={<MoreHorizOutlinedIcon style={{ color: theme.colors.icons.default }} />}
                  >
                    <button
                      aria-label="Remover membro"
                      type="button"
                      onClick={() => handleOpenRemoveFromGroup(
                        member.memberName,
                        member.memberId,
                        member.isAdm,
                      )}
                      style={{
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        border: `1px solid ${theme.colors.borders.main}`,
                      }}
                    >
                      <PersonRemoveAlt1OutlinedIcon />
                      Remover membro
                    </button>
                    {member.isAdm === 0 ? (
                      <button
                        aria-label="Promover a Admin"
                        type="button"
                        onClick={() => handleOpenPromoteToAdmin(
                          member.memberName,
                          member.memberId,
                          member.isAdm,
                        )}
                        style={{
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <SupervisorAccountOutlinedIcon />
                        Promover a Admin
                      </button>
                    ) : (
                      <button
                        aria-label="Revogar cargo de Admin"
                        type="button"
                        onClick={() => handleOpenPromoteToAdmin(member.memberName, member.memberId)}
                        style={{
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <SupervisorAccountOutlinedIcon />
                        Revogar cargo de Admin
                      </button>
                    )}
                  </PopOverMenu>
                  )}

                </div>
              </MemberContent>
            ))}
          {isCurrentUserAdmin && (
          <AddMemberButton
            aria-label="Adicionar membro"
            type="button"
            onClick={handleOpenAddingNewMembers}
          >
            <GroupAddOutlinedIcon />
            Adicionar
          </AddMemberButton>
          )}
        </MembersWrapper>

        <LeaveGroupButton
          aria-label="Sair do grupo"
          type="button"
          onClick={() => handleOpenLeaveGroup(user.id)}
        >
          <LogoutRoundedIcon />
          Sair do grupo
        </LeaveGroupButton>
      </MembersSection>

      <CustomDialog
        open={openPromoteToAdminDialog}
        onClose={handleClosePromoteToAdmin}
        onConfirm={() => {
          selectedMember.isAdm === 0
            ? handleConfirmPromoteToAdmin()
            : handleConfirmRevokeAdminRole();
        }}
        message={selectedMember.isAdm === 0 ? `Tem certeza que deseja promover ${selectedMember.memberName}` : 'Tem certeza que deseja revogar o cargo de Admin '}
        contactName={selectedMember.isAdm === 0 ? 'a Admin?' : `de ${selectedMember.memberName}?`}
        actionName={selectedMember.isAdm === 0 ? 'Promover' : 'Revogar'}
        confirmButtonColor={theme.colors.secondary}
      />

      <CustomDialog
        open={openLeaveGroupDialog}
        onClose={HandleCloseLeaveGroup}
        onConfirm={handleConfirmLeaveGroup}
        message="Tem certeza que deseja sair "
        contactName="do grupo?"
        actionName="Sair"
        confirmButtonColor={theme.colors.buttons.danger}
      />

      <CustomDialog
        open={openRemoveFromGroupDialog}
        onClose={handleCloseRemoveFromGroup}
        onConfirm={handleConfirmRemoveFromGroup}
        message={`Tem certeza que deseja remover ${selectedMember.memberName}`}
        contactName="do grupo?"
        actionName="Remover"
        confirmButtonColor={theme.colors.buttons.danger}
      />
      <CustomDialog
        open={isAddingNewMembers}
        onClose={handleCloseAddingNewMembers}
        onConfirm={handleConfirmAddNewMembers}
        message="Selecione os novos membros: "
        actionName="Confirmar"
        confirmButtonColor={theme.colors.secondary}
      >
        <NewMembersContainer>
          {allUsers
            .filter(
              (u) => u.id !== user.id
                && !currentChat.members.some((member) => member.memberId === u.id),
            )
            .map((user) => (
              <UserCheckbox key={user.id}>
                <label
                  htmlFor={`user-${user.id}`}
                >
                  <input
                    id={`user-${user.id}`}
                    type="checkbox"
                    checked={selectedMembersForAdding.some((member) => member.memberId === user.id)}
                    onChange={() => handleSelectNewMembers(user.id, user.name)}
                  />
                  {user.name}
                </label>
              </UserCheckbox>
            ))}
        </NewMembersContainer>
      </CustomDialog>
    </Container>
  );
}

EditProfileGroupChat.propTypes = {
  groupImageUrl: PropTypes.string,
  currentChat: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ambiente: PropTypes.string.isRequired,
    is_internal_chat: PropTypes.number,
    chat_name: PropTypes.string,
    chat_description: PropTypes.string,
    lastMessage: PropTypes.string.isRequired,
    mediaType: PropTypes.string.isRequired,
    mediaUrl: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    senderId: PropTypes.number.isRequired,
    unreadCount: PropTypes.number,
    is_group: PropTypes.number,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        memberId: PropTypes.number.isRequired,
        memberName: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
