export default {
  colors: {
    background: {
      main: '#EDEDED',
      secondary: '#F5F5F5',
      white: '#FFFFFF',
      input: '#E1E8F2',
      notification: '#DCE0E8',
      cardKanban: '#E6EFF7',
      reactedMessage: '#A1C7E0',
      messageFounded: '#FFCC22',
      sidebar: '#014F6D',
    },
    primary: '#FF6B35',
    secondary: '#014F6D',
    text: {
      main: '#525252',
      sidebar: '#91BBDE',
      sidebarUnseenMessage: '#919191',
      secondary: '#FFFFFF',
      kanbanTitle: '#01374D',
    },
    buttons: {
      background: '#F5F5F5',
      hover: '#EBEBEB',
      danger: '#FF0F0F',
    },
    icons: {
      default: '#919191',
      chatMessage: '#FFFFFF',
      red: '#FF0F0F',
    },
    borders: {
      main: '#CCC',
      secondary: '#DDD',
      third: '#AEBAC1',
      editProfile: '#AEBAC1',
    },
    messages: {
      unseen: '#F5F5F5',
      sidebarUnseen: '#919191',
      quotedSidebar: '#0098D9',
    },
    kanban: {
      priorityLow: '#4CAF50',
      priorityMedium: '#FFCC22',
      priorityHigh: '#FF0F0F',
    },
    extras: {
      blueTelein: '#0098D9',
      selectedComponent: '#33557B',
      originLeadChip: '#FF8C42',
    },
  },
};

export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
};
