import styled from 'styled-components';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  min-width: 10%;
  width: 25%;
  max-width: 25%;
  overflow-y: auto;
  gap: 10px;
  border-radius: 6px;
  padding: 10px;
  transition: background 0.2s ease;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ColumnTopLine = styled.div`
  height: 6px;
  width: 100%;
  background-color: ${(props) => props.color || '#4CAF50'};
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ColumnTitle = styled.h2`
  font-size: 18px;
  margin-bottom: -5px;
  color: ${({ theme }) => theme.colors.text.kanbanTitle};
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`;

export const ColumnSubtitle = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.main};
`;
