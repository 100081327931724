import styled from 'styled-components';

export const Container = styled.div`
  width: 40%;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.secondary};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 6px;
      border-radius: 20px;
      }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0,0,0,0.2);
    }


  .cancel-edit-contact-button {
    align-self: self-start;
    margin-left: 5%;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.background.main};
    border-radius: 50%;
    transition: 0.2s ease-out;

    &:hover {
        filter: brightness(0.8);
      }
  }

  .profile-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 10px 20px 10px;

    h1 {
    font-size: 24px;
    max-width: 100%;
    width: auto;
    }

    img {
    border-radius: 50%;
    padding: 10px;
    display: flex;
    height: auto;
    width: 60%;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    transition: 0.2s ease-in;
    margin-bottom: 30px;
  }

    span {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 0 10px 0;
      width: 70%;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-size: 16px;
        padding: 5px;
        border-radius: 50%;
        transition: 0.2s ease-out;

        &:hover {
          background-color: ${({ theme }) => theme.backgroundColor};
        }
      }
    }
  }

  .not-readed-div {
    padding: 20px 0px 20px 0;
    display: flex;
    align-items: center;

    button {
      font-size: 14px;
      margin-left: 5px;
      border-bottom: 1px solid transparent;


      &:hover {
        border-bottom: 1px solid #AEBAC1;
      }
    }
  }

  .profile-fields-wrapper {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.secondary};
    width: 100%;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: start;

    h2 {
      font-size: 20px;
      width: 90%;
      align-self: center;
    }

    textarea {
      text-align: left;
      align-self: center;
      margin: 20px 0;
      height: 200px;
      width: 90%;
      padding: 6px;
      font-size: 16px;
      overflow: auto;
      border: 1px solid #AEBAC1;
      resize: vertical;
    }

    .save-changes-button {
      align-self: center;
      color: white;
      background-color: ${({ theme }) => theme.colors.secondary};
      padding: 6px 8px;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      margin: 10px 0px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
        filter: brightness(0.8);
      }
    }
  }

  .tag-fields-wrapper {

    h2 {
      margin: 20px 0 10px 0;
    }
  }

  .available-tags-div {
  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.2);
  }
  }

`;

export const EditProfileChatInput = styled.input`
  width: calc(100% - 48px);
  border: 1px solid #AEBAC1;
  border-radius: 8px;
  padding: 8px 14px;
  background-color: transparent;
  font-size: 16px;
`;
