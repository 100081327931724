import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    overflow-y: auto;
  }

    &::-webkit-scrollbar {
      width: 10px;
      height: 6px;
      border-radius: 20px;
      }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.3);
    }

  .select-template-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .select-template-button {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin: 5px 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: 10px;
    transition: 0.2s;

    &:hover {
      filter: brightness(1.1);
      background-color: ${({ theme }) => theme.colors.extras.blueTelein};
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    background-color: ${({ theme }) => theme.colors.extras.blueTelein};

    &.pending {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    &.rejected {
      background-color: ${({ theme }) => theme.colors.buttons.danger};
    }
  }

  .delete-template-button {
    align-self: end;
    justify-content: end;
    color: ${({ theme }) => theme.colors.icons.chatMessage};
    background-color: transparent;
    border-radius: 50%;
    padding: 5px;
    transition: 0.2s;

    svg {
      font-size: 20px;

      &:hover {
      transform: scale(1.1);
      }
    }
  }

  .add-new-template-button {
    position: sticky;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: 10px;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
      filter: brightness(1.1);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }

  .load-template-wrapper {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
