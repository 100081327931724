import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.buttons.background};
  padding: 10px;
  min-width: 20%;
  width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 6px;
  flex-shrink: 0;
  cursor: move;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  position: relative;
`;

export const CardLeftLine = styled.div`
  width: 6px;
  height: 100%;
  background-color: ${(props) => props.color || '#4CAF50'};
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;

  button {
    align-self: flex-start;
    background-color: transparent;
  }

  svg {
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const AvatarImg = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

export const NameAndPhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: start;
  max-width: 100%;

  h2 {
      color: black;
      font-size: 18px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 600;
    }

  span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.text.main}
  }
`;

export const CardMain = styled.div`
  display: block;
  max-width: 100%;
  width: 210px;
  max-height: 500px;
  height: 100%;
  overflow: hidden;
  align-items: start;

  p {
    max-width: 100%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const CardTagSection = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
  gap: 4px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

export const CardTag = styled.div`
  padding: 4px 8px;
  background-color: #e0e0e0;
  border-radius: 16px;
  font-size: 10px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.text.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  user-select: none;

  &:hover {
    background-color: #d5d5d5;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    background-color: transparent;
  }

  svg {
    color: ${({ theme }) => theme.colors.text.main};
  }

  span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.text.main};
      align-self: flex-end;
  }
`;

export const CardActionsWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const DueDateSection = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.main};
  gap: 5px;

  div {
    display: flex;
    align-items: center;
  }

  h5 {
    color: ${({ theme }) => theme.colors.primary};
  }

  span {
    font-size: 14px;
  }

  svg {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: 18px;
  }
`;
