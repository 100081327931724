import styled from 'styled-components';

export const MembersWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borders.editProfile};
  border-radius: 6px;
  margin-top: 15px;
  width: 90%;
  align-self: center;
  margin: 20px 0;
`;

export const MemberContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.buttons.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.editProfile};
  transition: 0.1s ease-in;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
    border-bottom: none;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button {
    background-color: ${({ theme }) => theme.colors.buttons.background};
    transition: 0.1s ease-in;

    svg {
      transition: 0.1s ease-in;
      font-size: 25px;
      background-color: ${({ theme }) => theme.colors.buttons.background};
      color: ${({ theme }) => theme.colors.icons.default};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor};


    button {
      background-color: ${({ theme }) => theme.backgroundColor};
    }

    svg {
      background-color: ${({ theme }) => theme.backgroundColor};
    }
  }
`;

export const MembersSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 10px;

  h2 {
      font-size: 20px;
      width: 90%;
      align-self: center;
    }
`;

export const AddMemberButton = styled.button`
      display: flex;
      align-items: center;
      align-self: center;
      justify-self: center;
      gap: 10px;
      color: white;
      font-weight: 500;
      background-color: ${({ theme }) => theme.colors.secondary};
      padding: 6px 8px;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      margin: 10px 0px;
      transition: 0.2s;

      svg {
        font-size: 25px;
      }

      &:hover {
        filter: brightness(0.9);
      }
`;

export const LeaveGroupButton = styled.button`
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: center;
      color: white;
      font-weight: 500;
      background-color: ${({ theme }) => theme.colors.buttons.danger};
      padding: 6px 8px;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      margin: 10px 0px;
      transition: 0.2s;

      svg {
        font-size: 25px;
      }

      &:hover {
        filter: brightness(0.9);
      }
`;

export const NewMembersContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 10px;
      height: 6px;
    }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.2);
  }
`;
