import React, { useContext, useState, useRef } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomDialog from '../CustomDialog';
import KanbanBoardSettings from './KanbanBoardSettings';
import {
  formatPhoneNumber,
  baseUrl,
  getRequest,
  patchRequest,
  stripPrefix,
  postRequest,
} from '../../utils/services';
import KanbanColumn from './KanbanColumn';
import {
  Container,
  KanbanHeader,
  HeaderButtons,
  KanbanMain,
  SearchCardsWrapper,
  StyledTextField,
  ColumnsSection,
} from './styles';
import { ChatContext } from '../../context/ChatContext';

import { useErrors } from '../../context/ErrorContext';
import NewKanbanBoard from './NewKanbanBoard';
import { AuthContext } from '../../context/AuthContext';

export default function KanbanBoards() {
  const { user } = useContext(AuthContext);
  const {
    kanban,
    setKanban,
    showAlert,
  } = useContext(ChatContext);

  const theme = useTheme();

  const { kanbanBoards, currentKanban, showAddNewBoard } = kanban;

  const { setErrorMessage } = useErrors();

  const [kanbanSettings, setKanbanSettings] = useState({
    showKanbanSettings: false,
  });
  const [updatedKanbanData, setUpdatedKanbanData] = useState(null);
  const [kanbanInputSearch, setKanbanInputSearch] = useState('');

  const handleSaveBoardSettings = (updatedData) => {
    setUpdatedKanbanData({
      ...currentKanban,
      ...updatedData,
      columnOrder: updatedData.columnOrder,
      userId: user.id,
      columns: updatedData.columns.reduce((acc, column) => {
        acc[column.id] = column;
        return acc;
      }, {}),
    });
  };

  const removeFromColumnOrder = (columnOrder, id) => {
    return columnOrder.filter((columnIdInOrder) => columnIdInOrder !== id);
  };

  const removeFromColumns = (columns, columnId) => {
    return Object.values(columns || {})
      .filter((column) => column.numericId !== columnId)
      .reduce((acc, column) => {
        acc[column.numericId] = column;
        return acc;
      }, {});
  };

  const handleRemoveColumnFromKanban = (id, columnId) => {
    setKanban((prevKanban) => {
      const updatedColumnOrder = removeFromColumnOrder(prevKanban.currentKanban.columnOrder, id);
      const updatedColumns = removeFromColumns(prevKanban.currentKanban.columns, columnId);
      return {
        ...prevKanban,
        currentKanban: {
          ...prevKanban.currentKanban,
          columnOrder: updatedColumnOrder,
          columns: updatedColumns,
        },
      };
    });
  };

  const { showKanbanSettings } = kanbanSettings;

  const handleKanbanInputSearchChange = (event) => {
    const { value } = event.target;
    setKanbanInputSearch(value);
  };

  const handleConfirmNewBoardSettings = async () => {
    if (!updatedKanbanData) return;

    const {
      id,
      numericId,
      userId,
      description,
      name,
      columns,
    } = updatedKanbanData;

    const sanitizedColumns = Object.fromEntries(
      Object.entries(columns).map(([columnId, columnData]) => [
        columnId,
        {
          ...columnData,
          cardsIds: undefined,
        },
      ]),
    );

    const kanbanData = {
      id,
      numericId,
      userId,
      description,
      name,
      columns: sanitizedColumns,
    };

    try {
      const response = await patchRequest(
        `${baseUrl}/kanban/updateBoard/${numericId}`,
        JSON.stringify(kanbanData),
      );

      if (response.error) {
        setErrorMessage('Erro ao salvar alterações do quadro Kanban! Entre em contato com o suporte');
        return;
      }

      setKanban((prevKanban) => ({
        ...prevKanban,
        currentKanban: updatedKanbanData,
        kanbanBoards: prevKanban.kanbanBoards
          .map((board) => (board.id === numericId
            ? { ...board, name, description }
            : board)),
      }));

      setKanbanSettings({ showKanbanSettings: false });
      showAlert('Quadro Kanban atualizado com sucesso', 'success', 5000);
    } catch (error) {
      console.error(error);
      setErrorMessage('Erro ao atualizar quadro Kanban! Entre em contato com o suporte');
    }
  };

  const handleOpenKanbanSettings = () => {
    setKanbanSettings((prevKanbanSettings) => ({
      ...prevKanbanSettings,
      showKanbanSettings: true,
    }));
  };

  const handleCloseKanbanSettings = () => {
    setKanbanSettings((prevKanbanSettings) => ({
      ...prevKanbanSettings,
      showKanbanSettings: false,
    }));
  };

  const moveCard = async (cardId, sourceColumnId, destinationColumnId) => {
    if (sourceColumnId !== destinationColumnId) {
      const numericCardId = stripPrefix(cardId);
      const numericColumnId = stripPrefix(destinationColumnId);

      try {
        await patchRequest(`${baseUrl}/kanban/moveCard/${numericCardId}`, JSON.stringify({
          column_id: numericColumnId,
        }));
      } catch (error) {
        return setErrorMessage('Erro ao mover chat! Entre em contato com o suporte');
      }

      const sourceColumn = currentKanban.columns[sourceColumnId];
      const destinationColumn = currentKanban.columns[destinationColumnId];

      const newSourceCardsIds = sourceColumn.cardsIds.filter((id) => id !== cardId);
      const newDestinationCardsIds = [...destinationColumn.cardsIds, cardId];

      setKanban((prev) => ({
        ...prev,
        currentKanban: {
          ...prev.currentKanban,
          columns: {
            ...prev.currentKanban.columns,
            [sourceColumnId]: {
              ...sourceColumn,
              cardsIds: newSourceCardsIds,
            },
            [destinationColumnId]: {
              ...destinationColumn,
              cardsIds: newDestinationCardsIds,
            },
          },
        },
      }));
    }
  };

  const moveChat = async (selectedChat, destinationColumnId) => {
    const { chat } = selectedChat;
    const numericColumnId = stripPrefix(destinationColumnId);

    const contactName = chat?.customName
    || chat?.nome
    || formatPhoneNumber(chat?.fromMsg);

    const cardAlreadyExists = Object.values(currentKanban.cards).some(
      (card) => card.content.sender === chat.sender
      && card.content.fromMsg === chat.fromMsg,
    );

    if (cardAlreadyExists) {
      return showAlert('O chat em questão já consta no quadro atual', 'info', 5000);
    }

    try {
      const associatedTags = await getRequest(`${baseUrl}/chats/associatedTags/${user.ambiente}/${chat.fromMsg}`);

      const cardPayload = {
        title: contactName,
        description: chat.obs || null,
        profile_url: chat.profilePic || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635',
        sender: chat.sender,
        fromMsg: chat.fromMsg,
        column_id: numericColumnId,
        user_id: user.id,
        priority: 'baixa',
        contact_tags_id: associatedTags[0].contactId,
      };

      const response = await postRequest(`${baseUrl}/kanban/createCard`, JSON
        .stringify(cardPayload));

      if (response.error) {
        return setErrorMessage('Erro ao adicionar chat no Kanban! Entre em contato com o suporte');
      }

      const newCardId = response;

      const destinationColumn = currentKanban.columns[destinationColumnId];
      const newDestinationCardsIds = [...destinationColumn.cardsIds, `card-${newCardId}`];

      setKanban((prev) => ({
        ...prev,
        currentKanban: {
          ...prev.currentKanban,
          columns: {
            ...prev.currentKanban.columns,
            [destinationColumnId]: {
              ...destinationColumn,
              cardsIds: newDestinationCardsIds,
            },
          },
          cards: {
            ...prev.currentKanban.cards,
            [`card-${newCardId}`]: {
              content: {
                name: cardPayload.title,
                obs: cardPayload.description,
                sender: cardPayload.sender,
                phone: cardPayload.fromMsg,
                fromMsg: cardPayload.fromMsg,
                profilePic: chat.profilePic || null,
                comments: [],
                tags: associatedTags,
              },
              id: `card-${newCardId}`,
              priority: cardPayload.priority,
              updatedAt: Date.now(),
            },
          },
        },
      }));
    } catch (error) {
      return setErrorMessage('Erro ao adicionar chat no Kanban! Entre em contato com o suporte');
    }
  };

  const onDrop = (item, destinationColumnId) => {
    const { id: itemId, type: itemType } = item;

    if (itemType === 'CARD') {
      const sourceColumnId = item.columnId;
      if (sourceColumnId !== destinationColumnId) {
        return moveCard(itemId, sourceColumnId, destinationColumnId);
      }
    } else {
      return moveChat(item, destinationColumnId);
    }
  };

  const handleBackToHomeScreen = () => {
    setKanban({
      ...kanban,
      showKanbanSection: false,
    });
  };

  const handleSetCurrentKanban = async (board) => {
    const response = await getRequest(`${baseUrl}/kanban/board/${board.id}`);

    if (response.error) {
      return setErrorMessage('Não foi possível carregar Kanban! Entre em contato com o suporte');
    }

    const currentKanban = {
      name: board.name,
      description: board.description,
      ...response,
    };

    setKanban((prev) => ({
      ...prev,
      showAddNewBoard: false,
      currentKanban,
    }));
  };

  const handleShowAddNewKanbanBoard = () => {
    setKanban((prev) => ({
      ...prev,
      showAddNewBoard: !prev.showAddNewBoard,
    }));
  };

  return (
    <Container>
      <KanbanHeader>
        <HeaderButtons
          aria-label="voltar a tela inicial"
          type="button"
          onClick={handleBackToHomeScreen}
          style={{ marginRight: '20px' }}
        >
          <ArrowBackRoundedIcon fontSize="large" />
        </HeaderButtons>
        {kanbanBoards?.map((board) => {
          const isActive = board.id === currentKanban?.numericId && !showAddNewBoard;
          const buttonColor = isActive ? theme.colors.text.kanbanTitle : theme.colors.icons.default;

          return (
            <HeaderButtons
              aria-label="selecionar quadro kanban"
              type="button"
              color={buttonColor}
              isActive={isActive}
              onClick={() => handleSetCurrentKanban(board)}
            >
              <h2>{board.name}</h2>

              <Tooltip
                arrow
                title="Configurações do quadro"
              >
                <button
                  type="button"
                  aria-label="Configurações do quadro"
                  onClick={handleOpenKanbanSettings}
                >
                  <EditOutlinedIcon style={{ color: buttonColor }} />
                </button>
              </Tooltip>
            </HeaderButtons>
          );
        })}

        <HeaderButtons
          aria-label="Adicionar novo quadro kanban"
          type="button"
          onClick={handleShowAddNewKanbanBoard}
          style={{ marginLeft: '20px' }}
          color={showAddNewBoard
            ? theme.colors.text.kanbanTitle
            : theme.colors.icons.default}
          isActive={showAddNewBoard}
        >
          <AddIcon style={{
            fontSize: '26px',

          }}
          />
          <h2>Adicionar</h2>
        </HeaderButtons>
      </KanbanHeader>

      {showAddNewBoard
        ? (
          <NewKanbanBoard />
        ) : (
          <KanbanMain>
            <SearchCardsWrapper>
              <StyledTextField
                placeholder="Procurar chats"
                InputProps={{
                  onChange: handleKanbanInputSearchChange,
                  startAdornment: (
                    <SearchIcon style={{ marginRight: '8px', color: 'gray' }} />
                  ),
                }}
                variant="outlined"
              />
            </SearchCardsWrapper>
            <ColumnsSection
              style={{
                justifyContent: currentKanban?.columnOrder
                  ? 'flex-start'
                  : 'center',
              }}
            >

              {currentKanban?.columnOrder
                ? currentKanban?.columnOrder
                  .map((columnId) => {
                    const column = currentKanban?.columns[columnId];
                    const cards = column?.cardsIds?.map((cardId) => currentKanban.cards[cardId]);

                    return (
                      <KanbanColumn
                        key={column?.id}
                        column={column}
                        cards={cards}
                        onDrop={onDrop}
                        inputSearchForCards={kanbanInputSearch}
                      />
                    );
                  })
                : (
                  <h1>
                    Nenhum quadro Kanban selecionado
                    <h3>Selecione ou adicione um novo quadro no menu superior.</h3>
                  </h1>
                )}
            </ColumnsSection>

          </KanbanMain>
        )}
      <CustomDialog
        open={showKanbanSettings}
        onClose={handleCloseKanbanSettings}
        message={`Configurações de quadro ${currentKanban?.name}`}
        actionName="Salvar alterações"
        onConfirm={handleConfirmNewBoardSettings}
        confirmButtonColor={theme.colors.secondary}
      >
        <KanbanBoardSettings
          currentKanban={currentKanban}
          onSave={handleSaveBoardSettings}
          onClose={setKanbanSettings}
          onRemoveColumn={handleRemoveColumnFromKanban}
        />
      </CustomDialog>

    </Container>
  );
}
