import styled from 'styled-components';

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.buttons.danger};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease;

  svg {
    font-size: 20px;
    margin-right: 5px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
