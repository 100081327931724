import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin: 0 5px 10px 5px;
margin-bottom: 10px;
align-items: flex-start;

.custom-linkify-link {
  display: block;
  max-width: 300px;
}

.message-content-div {
  border-bottom: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 500px;
  min-width: 20%;
  word-wrap: break-word;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  .message-menu-button {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.icons.default};
    align-self: flex-end;
    margin-top: -5px;
    margin-bottom: -10px;
  }
  }

  .message-deleted-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 10px;
  }

  span {
    font-size: 14px;
  }

  audio {
    max-width: 100%;
  }

  embed {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-message-div {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .message-media-div {
    padding: 5px;
    max-width: 80%;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-player-div {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 500px;
    background-color: ${({ theme }) => theme.colors.buttons.background};
    border-radius: 12px;
    padding: 8px 12px;

    audio {
        flex-grow: 1;
    }

    button {
        background-color: transparent;
        padding: 4px;
        border-radius: 50%;
        transition: background-color 0.2s ease;

        svg {
            color: ${({ theme }) => theme.colors.icons.default};
        }

        &:hover {
            filter: brightness(0.7);
        }
    }
}

    .audio-transcription-div {
      background-color: ${({ theme }) => theme.colors.buttons.background};
      border-left: 4px solid #014F6D;
      padding: 16px;
      margin-top: 12px;
      border-radius: 6px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .transcription-text {
      font-style: italic;
      font-size: 1rem;
      color: #333;
      line-height: 1.5;
      margin: 0;
    }

  .time-and-confirmation-span {
    margin-top: 5px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: end;
  }

  .message-reacted-div {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    margin-top: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.background.reactedMessage};
    font-size: 15px;
    border: 1px solid ${({ theme }) => theme.colors.background.reactedMessage};
  }
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 20px 60px;
  border: 1px solid #AEBAC1;
  border-radius: 12px;
  background: linear-gradient(135deg, #F5F7FA, #E4EBF1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.2;
  }

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.extras.blueTelein};
    padding: 5px 12px;
    border-radius: 5px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  span {
    margin-bottom: 5px;
  }
`;

export const MessageMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 10px;
  text-align: center;
  font-size: 16px;
  width: 320px;
  font-weight: 600;
  transition: all 0.2s ease;
  background-color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  &:focus {
    outline: none;
  }

  & > .icon {
    font-size: 20px;
    margin-right: 5px;
  }
`;
