/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { io } from 'socket.io-client';
import moment from 'moment';
import {
  fixEncodingStrings,
  removeAfterSecondAmpersand,
  baseUrl,
  getRequest,
  patchRequest,
  postFormDataRequest,
  sendMessageRequest,
  baseMediaUrl,
  postRequest,
  webhookTeleinUrl,
  sendMessageUrl,
  sendMessageTestUrl,
  sendTemplateMessageUrl,
  showNotification,
  requestNotificationPermission,
} from '../utils/services';

import { useErrors } from './ErrorContext';
import { useFetchRecipientUser } from '../hooks/useFetchRecipientUser';
import useAlert from '../hooks/useAlert';
import { AuthContext } from './AuthContext';

export const ChatContext = createContext();

export function ChatContextProvider({ children, user }) {
  const [userChats, setUserChats] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('default');
  const [selectedAttendant, setSelectedAttendant] = useState('default');
  const [userChatsError, setUserChatsError] = useState(null);
  const [isUserChatsLoading, setIsUserChatsLoading] = useState(false);
  const [isOpenTicketsLoading, setIsOpenTicketsLoading] = useState(false);
  const [selectedSenderKey, setSelectedSenderKey] = useState('');
  const [isInternalChatsLoading, setIsInternalChatsLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(null);
  const [currentChat, setCurrentChat] = useState(null);
  const [sortedChats, setSortedChats] = useState([]);
  const [userChatsQueue, setUserChatsQueue] = useState([]);
  const [openTickets, setOpenTickets] = useState([]);
  const [internalChats, setInternalChats] = useState([]);
  const [archivedInternalChats, setArchivedInternalChats] = useState([]);
  const [openArchivedInternalChats, setOpenArchivedInternalChats] = useState(false);
  const [unreadInternalMessagesCount, setUnreadInternalMessagesCount] = useState(0);
  const [isTicketTransferred, setIsTicketTransferred] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [activeSectionChats, setActiveSectionChats] = useState('Aguardando');
  const [showNewChat, setShowNewChat] = useState(false);
  const [initialParamsForNewChat, setInitialParamsForNewChat] = useState(null);
  const [previousSenderChat, setPreviousSenderChat] = useState(null);
  const [previousFromMsgChat, setPreviousFromMsgChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [quotedMessages, setQuotedMessages] = useState([]);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [messagesError, setMessagesError] = useState(null);
  const [isAComment, setIsAComment] = useState(false);
  const [searchMessageValue, setSearchMessageValue] = useState('');
  const [messageToScroll, setMessageToScroll] = useState(null);
  const [messagesPage, setMessagesPage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingProfileChat, setIsEditingProfileChat] = useState(false);
  const [isEditingProfileGroupChat, setIsEditingProfileGroupChat] = useState(false);
  const [selectedImageToSend, setSelectedImageToSend] = useState(null);
  const [urlImageToSend, setUrlImageToSend] = useState([]);
  const [selectedVideoToSend, setSelectedVideoToSend] = useState(null);
  const [urlVideoToSend, setUrlVideoToSend] = useState([]);
  const [selectedDocumentToSend, setSelectedDocumentToSend] = useState(null);
  const [urlDocumentToSend, setUrlDocumentToSend] = useState([]);
  const [isMediaUploadLoading, setIsMediaUploadLoading] = useState(false);
  const [mediaUploadProgress, setMediaUploadProgress] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioBlobURL, setAudioBlobURL] = useState(null);
  const [isRecordingAudio, setIsRecordingAudio] = useState(false);
  const [isUploadingAudioMessage, setIsUploadingAudioMessage] = useState(false);
  const [scheduledMessages, setScheduledMessages] = useState([]);
  const [chatMarkedAsUnread, setChatMarkedAsUnread] = useState(false);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const [sessionConflict, setSessionConflict] = useState({
    showSessionConflict: false,
    sessionConflictData: null,
  });
  const [quickAnswers, setQuickAnswers] = useState({
    showQuickAnswers: false,
    messageIdToQuickAnswer: '',
    isAddingNewAnswer: false,
    openClearAllQuickAnswers: false,
    openClearSelectedQuickAnswer: false,
    selectedQuickAnswerToClear: {},
    messagesWithMediaUrl: [],
    quickAnswersData: [],
  });
  const [chatTags, setChatTags] = useState({
    currentContactId: null,
    associatedTags: [],
    availableTags: [],
    newTag: '',
  });
  const [kanban, setKanban] = useState({
    kanbanBoards: [],
    currentKanban: {},
    currentChatKanbans: [],
    showKanbanSection: false,
    showAddNewBoard: false,
  });

  const [audioTranscription, setAudioTranscription] = useState({
    showAudioTranscription: false,
    isLoadingTranscription: false,
    transcriptionText: [],
  });

  const [officialApiTemplates, setOfficialApiTemplates] = useState({
    open: false,
    loading: false,
    data: [],
    selectedToSend: null,
    isCurrentChatFromOfficialApi: false,
    isOfficialApiMessageExpired: false,
    isOfficialApiTemplateSended: false,
    isCreatingNewTemplate: false,
    isDeletingSelectedTemplate: false,
  });

  moment.locale('pt-br');

  const { logoutUser } = useContext(AuthContext);

  const { messagesWithMediaUrl } = quickAnswers;

  const { alert, showAlert, hideAlert } = useAlert();

  const containerRef = useRef(null);
  const isInitialMount = useRef(true);

  const playOpenChatNotification = () => {
    const notificationSound = new Audio(
      'https://chat.telein.com.br/assets/audios/openchatnotification.mp3',
    );

    notificationSound.play().catch((error) => {
      console.error('Error playing notification sound: ', error);
    });
  };

  const playNotificationSound = () => {
    const notificationSound = new Audio(
      'https://chat.telein.com.br/assets/audios/notificationsound.mp3',
    );

    notificationSound.play().catch((error) => {
      console.error('Error playing notification sound: ', error);
    });
  };

  const scrollChatToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
      });
    }
  };

  const handleScrollChatToBottom = () => {
    scrollChatToBottom();
    isInitialMount.current = false;
  };

  const { recipientUser } = useFetchRecipientUser(currentChat, user);

  const { setErrorMessage } = useErrors();

  const getAllUsers = async () => {
    const response = await getRequest(
      `${baseUrl}/users/${user.id}/${user.ambiente}`,
    );

    if (response.error) {
      setErrorMessage(
        'Erro ao buscar todos usuários. Entre em contato com o suporte',
      );
    }

    setAllUsers(response);
  };

  const getDepartments = async () => {
    const response = await getRequest(
      `${baseUrl}/chats/departments/${user?.ambiente}`,
    );

    if (response.error) {
      setErrorMessage(
        'Erro ao buscar todos ambientes. Entre em contato com o suporte',
      );
    }

    setDepartments(response);
  };

  const getUserChats = async (page) => {
    setIsUserChatsLoading(true);
    setUserChatsError(null);

    const response = await getRequest(
      `${baseUrl}/chats/${user?.id}?page=${page}&limit=15`,
    );

    if (response.error) {
      setUserChatsError(response);
      return [];
    }

    const filteredResponse = response.filter((newChat) => {
      return !userChats?.some(
        (existingChat) => existingChat.sender === newChat.sender
          && existingChat.fromMsg === newChat.fromMsg,
      );
    });

    setUserChats((prevChats) => [...prevChats, ...filteredResponse]);
    setIsUserChatsLoading(false);
    return response;
  };

  const getUserChatsQueue = async () => {
    setIsUserChatsLoading(true);
    const response = await getRequest(`${baseUrl}/chats/chatQueue/${user?.id}`);

    if (response.error) {
      setErrorMessage('Erro ao buscar fila de chamados! Entre em contato com o suporte.');
    }

    setUserChatsQueue(response);
    setIsUserChatsLoading(false);
  };

  const getOpenTickets = async () => {
    setIsOpenTicketsLoading(true);
    const response = await getRequest(
      `${baseUrl}/chats/openedTickets/${user?.id}`,
    );

    if (response.error) {
      return setErrorMessage(
        'Erro ao buscar chamados em aberto. Entre em contato com o suporte',
      );
    }

    setOpenTickets(response);
    setIsOpenTicketsLoading(false);
  };

  const getInternalChats = async () => {
    setIsInternalChatsLoading(true);

    const response = await getRequest(
      `${baseUrl}/chats/internalChats/${user?.id}`,
    );

    if (response.error) {
      return setErrorMessage(
        'Erro ao buscar chats internos. Entre em contato com o suporte',
      );
    }

    const archivedInternalChats = response.filter(
      (chat) => chat.arquivado === 1,
    );
    setInternalChats(response);
    setArchivedInternalChats(archivedInternalChats || []);
    setIsInternalChatsLoading(false);
  };

  const getQuickAnswers = async () => {
    const response = await getRequest(
      `${baseUrl}/messages/quickAnswers/${user?.id}`,
    );

    if (response.error) {
      return setErrorMessage(
        'Erro ao buscar mensagens rápidas do usuário. Entre em contato com o suporte',
      );
    }

    setQuickAnswers({
      ...quickAnswers,
      quickAnswersData: response,
    });
  };

  const areChatsEqual = (chat1, chat2) => {
    if (!chat1 || !chat2) return false;

    return chat1.sender === chat2.sender && chat1.fromMsg === chat2.fromMsg;
  };

  useEffect(() => {
    if (user) {
      getOpenTickets();
      getUserChatsQueue();
      getUserChats(1);
      getInternalChats();
      getAllUsers();
      getDepartments();
      getQuickAnswers();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const interval = setInterval(async () => {
        getUserChatsQueue();
      }, 120000);

      return () => clearInterval(interval);
    }
  }, [user]);

  const calculateTotalUnreadCount = () => {
    const unreadInOpenTickets = openTickets.reduce(
      (sum, ticket) => sum + (ticket.unreadCount || 0),
      0,
    );
    const unreadInUserChatsQueue = userChatsQueue.reduce(
      (sum, chat) => sum + (chat.unreadCount || 0),
      0,
    );
    return unreadInOpenTickets + unreadInUserChatsQueue;
  };

  useEffect(() => {
    const unreadInternalMessages = internalChats.reduce(
      (acc, chat) => acc + (chat.unreadCount || 0),
      0,
    );

    setUnreadInternalMessagesCount(unreadInternalMessages);
  }, [internalChats]);

  useEffect(() => {
    const totalUnreadCount = calculateTotalUnreadCount();
    document.title = totalUnreadCount > 0 ? `Chat Telein (${totalUnreadCount})` : 'Chat Telein';

    return () => {
      document.title = 'Chat Telein';
    };
  }, [userChatsQueue, openTickets]);

  const handleCreateTicketClosedNotification = async (
    sender,
    fromMsg,
    ambiente,
  ) => {
    const transferTicketDate = Date.now();

    const payload = {
      sender,
      fromMsg,
      ambiente,
      api: officialApiTemplates.isCurrentChatFromOfficialApi ? 1 : 0,
      body: `Fim do chamado - Encerrado por ${user.name} - ${moment(
        transferTicketDate,
      ).format('DD/MM/YYYY HH:mm:ss')}`,
      ack: 0,
      mediaType: 'text/notification',
      fromMe: 2,
      isDeleted: null,
      read: 1,
    };

    const response = await postRequest(
      `${baseUrl}/chats/insertNotification`,
      JSON.stringify(payload),
    );

    socket.emit('sendedMessage', currentChat, payload, user.ambiente, user.id);

    if (response.error) {
      return setErrorMessage(
        'Falha ao criar notificação de encerramento de chamado. Entre em contato com o suporte.',
      );
    }
  };

  const handleTransferTicket = async (
    toAgentId,
    sender,
    fromMsg,
    setorId,
    ambiente,
  ) => {
    const previousSetor = departments.find(
      (department) => currentChat.setor_id === department.id,
    );
    const selectedDepartmentName = departments.find(
      (department) => setorId === department.id,
    );

    const selectedAttendantName = allUsers.find(
      (attendant) => selectedAttendant === attendant.id,
    );

    const transferTicketDate = Date.now();

    const payloadTicket = {
      fromAgentId: user.id,
      toAgentId,
      sender,
      fromMsg,
      setor_id: setorId,
      ambiente,
    };

    let notificationBody = `Chamado transferido por (${user.name}) - De ${
      previousSetor?.nome_setor
    } para ${selectedDepartmentName?.nome_setor} (${
      selectedAttendant === 'default' ? 'Fila' : selectedAttendantName?.name
    }) - ${moment(transferTicketDate).format('DD/MM/YYYY HH:mm:ss')}`;

    if (!previousSetor) {
      notificationBody = `Chamado iniciado por (${user.name}) - ${moment(
        transferTicketDate,
      ).format('DD/MM/YYYY HH:mm:ss')}`;
    }

    let notificationPayload = {
      id: currentChat.id,
      usuario_id: selectedAttendant === 'default' ? 0 : selectedAttendant,
      nome: currentChat.nome,
      api: officialApiTemplates.isCurrentChatFromOfficialApi ? 1 : 0,
      sender,
      fromMsg,
      ambiente,
      body: notificationBody,
      ack: 0,
      customName: currentChat.customName,
      mediaType: 'text/notification',
      fromMe: 2,
      isDeleted: null,
      status: selectedAttendant === 'default' ? 1 : 2,
      setor_id: setorId,
      nome_setor: selectedDepartmentName.nome_setor,
      read: 1,
      createdAt: Date.now(),
    };

    const patchResponse = await patchRequest(
      `${baseUrl}/chats/transferTicket`,
      JSON.stringify(payloadTicket),
    );

    const postResponse = await postRequest(
      `${baseUrl}/chats/insertNotification`,
      JSON.stringify(notificationPayload),
    );

    if (patchResponse.error || postResponse.error) {
      return setErrorMessage(
        'Erro ao transferir chamado. Entre em contato com o suporte',
      );
    }

    if (patchResponse.id) {
      notificationPayload = {
        ...notificationPayload,
        id: patchResponse.id,
      };
    }

    if (selectedAttendant === user.id) {
      const updatedQueue = userChatsQueue.filter((chatQueue) => {
        return chatQueue.id !== currentChat.id;
      });
      setUserChatsQueue(updatedQueue);
    }

    const userHasAccessToDepartment = user.setores.some(
      (setor) => setor.id_setor === setorId,
    );

    if ((selectedAttendant === 'default' && userHasAccessToDepartment) || selectedAttendant !== user.id) {
      const updatedOpenTickets = openTickets.filter((ticket) => {
        return ticket.id !== currentChat.id;
      });
      setOpenTickets(updatedOpenTickets);
      setCurrentChat(null);
    }

    socket.emit(
      'ticketTransferred',
      currentChat,
      notificationPayload,
      user.ambiente,
    );

    setIsTicketTransferred(true);
    setSelectedDepartment('default');
    setSelectedAttendant('default');
  };

  const sortUserChatsByRecentMessages = (chats) => chats
    ?.slice()
    .sort(
      (chatA, chatB) => new Date(chatB.createdAt) - new Date(chatA.createdAt),
    );

  const updateUserChatsWithNewMessage = (chats, notification) => {
    const isChatOpen = currentChat?.sender === notification.sender
      && currentChat?.fromMsg === notification.fromMsg;
    const updatedChats = chats.map((chat) => {
      if (
        chat.sender === notification.sender
        && chat.fromMsg === notification.fromMsg
      ) {
        return {
          ...chat,
          body: notification.body,
          createdAt: notification.createdAt,
          fromMe: notification.fromMe,
          mediaType: notification.mediaType,
          mediaUrl: notification.mediaUrl,
          unreadCount:
            isChatOpen || notification.fromMe === 1
              ? chat.unreadCount
              : (chat.unreadCount || 0) + 1,
          read: isChatOpen ? 1 : null,
        };
      }
      return chat;
    });

    return sortUserChatsByRecentMessages(updatedChats);
  };

  const updateInternalChatsWithNewMessage = async (chats, newMessage) => {
    const isChatOpen = currentChat?.id === newMessage.chatId;

    let newMessageChat = chats.find((chat) => chat.id === newMessage.chatId);

    if (newMessageChat?.arquivado === 1) {
      newMessageChat = {
        ...newMessageChat,
        arquivado: 0,
      };

      setArchivedInternalChats((prevArchived) => prevArchived
        .filter((archived) => archived.id !== newMessageChat.id));

      setOpenArchivedInternalChats(false);
    }

    const updatedInternalChats = chats.map((chat) => {
      if (chat.id === newMessage.chatId) {
        return {
          ...(newMessageChat || chat),
          lastMessage: newMessage.body,
          createdAt: newMessage.createdAt,
          mediaType: newMessage.mediaType,
          mediaUrl: newMessage.mediaUrl,
          unreadCount: isChatOpen
            ? chat.unreadCount
            : (chat.unreadCount || 0) + 1,
        };
      }
      return chat;
    });

    if (!newMessageChat) {
      try {
        const response = await getRequest(
          `${baseUrl}/chats/getInternalChatById/${newMessage.chatId}/${user.id}`,
        );
        if (response) {
          updatedInternalChats.push(response);
        }
      } catch (error) {
        console.error('Error fetching new chat data:', error);
      }
    }

    return sortUserChatsByRecentMessages(updatedInternalChats);
  };

  const updateUserChatFromQueueToChats = async (currentChat) => {
    const isAlreadyInChats = openTickets.some(
      (ticket) => ticket.sender === currentChat.sender
        && ticket.fromMsg === currentChat.fromMsg,
    );

    if (isAlreadyInChats) return;

    const transferTicketDate = Date.now();
    const previousAttendant = allUsers.find(
      (user) => user.id === currentChat?.usuario_id,
    );

    let notificationBody = `Chamado transferido por (${user.name}) - De ${
      currentChat.nome_setor
    } (${
      currentChat.status === 1
        ? 'Fila'
        : previousAttendant
          ? previousAttendant.name
          : 'N/A'
    }) para ${user.setores[0].nome_setor} (${user.name}) - ${moment(
      transferTicketDate,
    ).format('DD/MM/YYYY HH:mm:ss')}`;

    if (currentChat.status === 3 || !currentChat.id) {
      notificationBody = `Chamado iniciado por (${user.name}) - ${moment(
        transferTicketDate,
      ).format('DD/MM/YYYY HH:mm:ss')}`;
    }

    const notificationPayload = {
      id: currentChat.id,
      sender: currentChat.sender,
      fromMsg: currentChat.fromMsg,
      ambiente: user.ambiente,
      body: notificationBody,
      ack: 0,
      api: officialApiTemplates.isCurrentChatFromOfficialApi ? 1 : 0,
      mediaType: 'text/notification',
      customName: currentChat.customName,
      fromMe: 2,
      status: 2,
      read: 1,
      nome: currentChat.nome,
      isDeleted: null,
      usuario_id: user.id,
      setor_id: user.setores[0].id_setor,
      nome_setor: user.setores[0].nome_setor,
      createdAt: Date.now(),
    };

    const createNotificationResponse = await postRequest(
      `${baseUrl}/chats/insertNotification`,
      JSON.stringify(notificationPayload),
    );

    if (currentChat.status === 3 || !currentChat.id) {
      const createTicketResponse = await postRequest(
        `${baseUrl}/chats/createTicket`,
        JSON.stringify({
          ambiente: user.ambiente,
          sender: currentChat?.sender,
          fromMsg: currentChat?.fromMsg,
          userId: user.id,
          setor_id: user.setores[0].id_setor,
        }),
      );

      if (createNotificationResponse.error || createTicketResponse.error) {
        return setErrorMessage(
          'Algo de errado aconteceu. Entre em contato com o suporte',
        );
      }

      const getTicketByIdResponse = await getRequest(
        `${baseUrl}/chats/findById/${createTicketResponse}`,
      );

      if (getTicketByIdResponse.error) {
        return setErrorMessage(
          'Não foi possível assumir esse chamado! Entre em contato com o suporte.',
        );
      }

      setCurrentChat(getTicketByIdResponse);

      const newTicketIdPayload = {
        ...notificationPayload,
        id: getTicketByIdResponse.id,
      };

      socket.emit(
        'ticketTransferred',
        getTicketByIdResponse,
        newTicketIdPayload,
        user.ambiente,
      );
      return;
    }

    const updateTicketResponse = await patchRequest(
      `${baseUrl}/chats/update`,
      JSON.stringify({
        userId: user.id,
        ticketId: currentChat?.id,
      }),
    );

    socket.emit(
      'ticketTransferred',
      currentChat,
      notificationPayload,
      user.ambiente,
    );

    if (updateTicketResponse.error || createNotificationResponse.error) {
      return setErrorMessage(
        'Algo de errado aconteceu. Entre em contato com o suporte',
      );
    }

    setUserChatsQueue((prevChatsQueue) => prevChatsQueue
      .filter((chat) => chat.id !== currentChat.id));
  };

  const updateTicketToClosed = async () => {
    const response = await patchRequest(
      `${baseUrl}/chats/closeTicket`,
      JSON.stringify({ ticketId: currentChat?.id, userId: user.id }),
    );

    if (response.error) {
      return setErrorMessage(
        'Erro ao encerrar chamado. Entre em contato com o suporte',
      );
    }

    socket.emit('ticketClosed', currentChat, user?.ambiente);

    setOpenTickets((prevOpenTickets) => prevOpenTickets
      .filter((ticket) => ticket.id !== currentChat.id));
    setUserChatsQueue((userChatsQueue) => userChatsQueue
      .filter((ticket) => ticket.id !== currentChat.id));

    showAlert('Chamado encerrado com sucesso', 'success', 5000);
  };

  useEffect(() => {
    if (user) {
      const socketURL = 'https://chat.telein.com.br:4000';
      const newSocket = io(socketURL);
      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [user]);

  useEffect(() => {
    if (!socket) return;

    socket.emit('addNewUser', user?.id, user?.senders, user?.ambiente);

    socket.on('getOnlineUsers', (res) => {
      setOnlineUsers(res);
    });

    socket.on('forceLogout', (res) => {
      logoutUser();
    });

    return () => {
      socket.off('getOnlineUsers');
      socket.off('forceLogout');
    };
  }, [socket, user]);

  const fetchSignedMediaUrl = async (mediaKey) => {
    try {
      const response = await postRequest(
        `${baseUrl}/messages/sign-media-url`,
        JSON.stringify({ key: mediaKey }),
      );

      if (response.error) {
        setErrorMessage(
          'Não foi possível carregar mídia da mensagem! Entre em contato com o suporte.',
        );
        return;
      }

      return response;
    } catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (!socket) return;

    socket.on('receiveInternalMessage', async (message) => {
      let messageWithMedia = { ...message };

      if (message.mediaUrl) {
        const signedMediaUrl = await fetchSignedMediaUrl(message.mediaUrl);
        messageWithMedia = { ...messageWithMedia, mediaUrl: signedMediaUrl };
      }

      if (message.quotedMsgId) {
        const quotedMessageResponse = await getRequest(
          `${baseUrl}/messages/internalMessage/${message.quotedMsgId}`,
        );

        setQuotedMessages((prevQuotedMessages) => [
          ...prevQuotedMessages,
          quotedMessageResponse,
        ]);
      }

      if (message.chatId === currentChat?.id) {
        setMessages((prev) => [messageWithMedia, ...prev]);
        setTimeout(handleScrollChatToBottom, 500);
      }

      const updatedInternalChats = await updateInternalChatsWithNewMessage(
        internalChats,
        messageWithMedia,
      );

      setInternalChats(updatedInternalChats);

      const chatFromReceivedMessage = internalChats.find((chat) => chat.id === message.chatId);

      showNotification(`Você recebeu uma nova mensagem de ${chatFromReceivedMessage?.chat_name || chatFromReceivedMessage?.memberName}`, {
        body: message?.body || 'Nova mensagem',
        icon: 'https://chat.telein.com.br/assets/images/telein-logo-att.png',
      });

      requestNotificationPermission();
    });

    socket.on('updateEditedMessageBody', (updatedMessage, userId) => {
      if (updatedMessage.chatId === currentChat?.id && user.id !== userId) {
        setMessages((prevMessages) => prevMessages.map((msg) => (msg.id === updatedMessage.id
          ? { ...msg, body: updatedMessage.body, isEdited: 1 }
          : msg)));
      }
    });

    socket.on('updateDeletedMessage', async (idMsg, currentChatId, userId) => {
      if (currentChatId === currentChat?.id && user.id !== userId) {
        setMessages((prevMessages) => prevMessages
          .map((msg) => (msg.id === idMsg
            ? { ...msg, isDeleted: 1 } : msg)));
      }
    });

    return () => {
      socket.off('receiveInternalMessage');
      socket.off('updateEditedMessageBody');
      socket.off('updateDeletedMessage');
    };
  }, [socket, internalChats, currentChat]);

  useEffect(() => {
    if (!socket) return;
    socket.on('updatedTicketsTransferred', (previousChat, notification) => {
      const userHasAccessToDepartment = user.setores.some(
        (setor) => setor.id_setor === notification.setor_id,
      );

      if (
        currentChat?.sender === notification.sender
        && currentChat?.fromMsg === notification.fromMsg
      ) {
        setCurrentChat(notification);
        setMessages((prevMessages) => [notification, ...prevMessages]);
        setTimeout(handleScrollChatToBottom, 500);
      }

      if (previousChat.status === 1) {
        setUserChatsQueue((prevChatsQueue) => prevChatsQueue
          .filter((chat) => chat.id !== notification.id));
      }

      if (notification.status === 1 && notification.usuario_id === 0) {
        if (!userHasAccessToDepartment) return;

        setUserChatsQueue((prevChats) => [notification, ...prevChats]);
      }

      if (notification.status === 2 && notification.usuario_id === user.id) {
        setOpenTickets((prevTickets) => [notification, ...prevTickets]);
      }
    });

    socket.on('updatedTicketToClosed', (closedTicket) => {
      setUserChatsQueue((prevChatsQueue) => prevChatsQueue
        .filter((chat) => chat.id !== closedTicket.id));
    });

    return () => {
      socket.off('updatedTicketsTransferred');
      socket.off('updatedTicketToClosed');
    };
  }, [socket, currentChat]);

  useEffect(() => {
    if (!socket) return;

    socket.on('sessionConflict', (data) => {
      if (data.existingSession.userId === user.id) {
        setSessionConflict({
          showSessionConflict: true,
          sessionConflictData: data,
        });
      }
    });

    return () => {
      socket.off('sessionConflict');
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on('receiveMessage', (previousChat, message, userId) => {
      const updatedSortedChats = updateUserChatsWithNewMessage(
        sortedChats,
        message,
      );

      const updatedChats = updatedSortedChats.map((chat) => (areChatsEqual(chat, previousChat)
        ? { ...chat, unreadCount: 0 } : chat));

      setSortedChats(updatedChats);

      if (areChatsEqual(currentChat, message) && user.id !== userId) {
        setMessages((prevMessages) => [message, ...prevMessages]);
        setTimeout(handleScrollChatToBottom, 500);
      }
    });

    socket.on('receiveComment', (commentPayload) => {
      const updatedSortedChats = updateUserChatsWithNewMessage(
        sortedChats,
        commentPayload,
      );

      const updatedChats = updatedSortedChats.map((chat) => (areChatsEqual(chat, commentPayload)
        ? { ...chat, unreadCount: 0 } : chat));

      setSortedChats(updatedChats);

      if (areChatsEqual(currentChat, commentPayload)) {
        setMessages((prevMessages) => [commentPayload, ...prevMessages]);
        setTimeout(handleScrollChatToBottom, 500);
      }
    });

    return () => {
      socket.off('receiveMessage');
      socket.off('receiveComment');
    };
  }, [socket, currentChat, setMessages, sortedChats]);

  useEffect(() => {
    const checkRecipientOnline = () => {
      if (!recipientUser?.id) {
        setIsRecipientOnline(false);
      }
      const recipientOnline = onlineUsers?.some(
        (u) => recipientUser?.id === u.userId,
      );
      setIsRecipientOnline(recipientOnline);
    };
    checkRecipientOnline();
  }, [onlineUsers, recipientUser]);

  const fetchMediaUrls = async (response) => {
    if (Array.isArray(response)) {
      const messagesWithMedia = await Promise.all(
        response.map(async (res) => {
          if (
            !res.mediaType
            || res.mediaType === 'text/notification'
            || res.mediaType === 'quotedMessage'
            || res.mediaType === 'contactMessage'
            || res.mediaType === 'reactMsg'
            || res.mediaType === 'template'
          ) return res;

          try {
            if (res.mediaType === 'video/mp4') {
              const thumbResponse = await getRequest(
                `${baseMediaUrl}${res.mediaUrl}.thumb`,
              );
              res.videoThumb = thumbResponse.url;
            }
            const mediaResponse = await getRequest(
              `${baseMediaUrl}${res.mediaUrl}`,
            );
            res.mediaUrl = mediaResponse.url;
          } catch (error) {
            console.error('Error getting messages with media: ', error);
          }

          return res;
        }),
      );

      return messagesWithMedia;
    }
    if (response && typeof response === 'object') {
      if (response.mediaType && response.mediaType !== '') {
        try {
          if (response.mediaType === 'video/mp4') {
            const thumbResponse = await getRequest(
              `${baseMediaUrl}${response.mediaUrl}.thumb`,
            );
            response.videoThumb = thumbResponse.url;
          }
          const mediaResponse = await getRequest(
            `${baseMediaUrl}${response.mediaUrl}`,
          );
          response.mediaUrl = mediaResponse.url;
        } catch (error) {
          console.error('Error getting messages with media: ', error);
        }
      }

      return response;
    }
    return setErrorMessage(
      'Erro ao receber mensagem com mídia. Entre em contato com o suporte.',
    );
  };

  const currentTime = new Date();

  const handleSetIsOfficialApiTemplateExpired = () => {
    const hasReceivedMessages = messages.some((msg) => msg.fromMe === 0);

    const mostRecentMessage = messages
      .filter((msg) => msg.api === 1 && (hasReceivedMessages ? msg.fromMe === 0 : true))
      .reduce((recent, msg) => {
        const messageCreatedAt = new Date(msg.createdAt);
        const recentCreatedAt = new Date(recent.createdAt);
        return messageCreatedAt > recentCreatedAt ? msg : recent;
      }, { createdAt: 0 });

    const isOfficialApiMessageExpired = mostRecentMessage.createdAt !== 0
      && currentTime - new Date(mostRecentMessage.createdAt) > 24 * 60 * 60 * 1000;

    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isOfficialApiMessageExpired,
    }));
  };

  const handleSetIsOfficialApiTemplateSended = () => {
    const mostRecentMessage = messages.find(
      (msg) => msg.api === 1 && msg.fromMe === 1 && msg.mediaType === 'template',
    );

    const isOfficialApiTemplateSended = mostRecentMessage
      && messages.indexOf(mostRecentMessage) === 0
      && currentTime - new Date(mostRecentMessage.createdAt) <= 24 * 60 * 60 * 1000;

    setOfficialApiTemplates((prevTemplates) => ({
      ...prevTemplates,
      isOfficialApiTemplateSended,
    }));
  };

  useEffect(() => {
    if (!socket) return;

    socket.on('getMessage', async (res) => {
      try {
        const userHasAccessToSenderOfMessage = Object.keys(user.senders).some(
          (key) => key === res.sender,
        );

        if (!userHasAccessToSenderOfMessage) return;

        const isChatOpen = currentChat?.fromMsg === res?.fromMsg
          && currentChat?.sender === res?.sender;

        if (isChatOpen) {
          const messagesWithMedia = await fetchMediaUrls(res);

          if (res.quotedMessage) {
            setQuotedMessages((prevQuotedMessages) => [
              ...prevQuotedMessages,
              res.quotedMessage,
            ]);
          }
          const updatedSortedChats = updateUserChatsWithNewMessage(
            sortedChats,
            messagesWithMedia,
          );
          setSortedChats(updatedSortedChats);

          setMessages((prev) => [messagesWithMedia, ...prev]);
          if (res.fromMe === 0 && res.idMsg.startsWith('wamid')) {
            setOfficialApiTemplates((prevTemplates) => ({
              ...prevTemplates,
              isOfficialApiMessageExpired: false,
            }));
          }

          setTimeout(handleScrollChatToBottom, 500);

          if (currentChat.usuario_id === user.id) {
            const updatedOpenTickets = updateUserChatsWithNewMessage(
              openTickets,
              res,
            );
            setOpenTickets(updatedOpenTickets);
          }

          if (!isRecordingAudio) {
            playOpenChatNotification();
          }
        }

        const isAlreadyInSortedChats = sortedChats.some(
          (chat) => chat.sender === res.sender && chat.fromMsg === res.fromMsg,
        );

        if (isAlreadyInSortedChats) {
          const updatedSortedChats = updateUserChatsWithNewMessage(
            sortedChats,
            res,
          );
          setSortedChats(updatedSortedChats);
        } else {
          setSortedChats((prevSortedChats) => [res, ...prevSortedChats]);
        }

        const isForMyOpenTickets = openTickets.some(
          (ticket) => ticket.sender === res.sender && ticket.fromMsg === res.fromMsg,
        );

        if (isForMyOpenTickets) {
          const updatedOpenTickets = updateUserChatsWithNewMessage(
            openTickets,
            res,
          );

          setOpenTickets(updatedOpenTickets);

          if (!isRecordingAudio) {
            playNotificationSound();
          }

          return;
        }

        const isAlreadyInQueue = userChatsQueue.some(
          (chat) => chat.sender === res.sender && chat.fromMsg === res.fromMsg,
        );

        if (isAlreadyInQueue) {
          const updatedUserChatsQueue = updateUserChatsWithNewMessage(
            userChatsQueue,
            res,
          );

          setUserChatsQueue(updatedUserChatsQueue);
          if (res.fromMe !== 1 && !isRecordingAudio) {
            playNotificationSound();
          }

          return;
        }

        const userHasAccessToTicket = user.setores.some(
          (setor) => setor.id_setor === res.setor_id,
        );

        if (res.status === 1 && userHasAccessToTicket) {
          setUserChatsQueue((prevChatsQueue) => {
            const chatExists = prevChatsQueue.some(
              (chat) => chat.sender === res.sender && chat.fromMsg === res.fromMsg,
            );

            if (!chatExists) {
              if (!isRecordingAudio) {
                playNotificationSound();
              }
              return [res, ...prevChatsQueue];
            }

            return prevChatsQueue;
          });
        }
      } catch (error) {
        setErrorMessage('Algo deu errado! Entre em contato com o suporte.');
      }
    });

    return () => {
      socket.off('getMessage');
    };
  }, [socket,
    currentChat,
    userChatsQueue,
    openTickets,
    sortedChats,
    officialApiTemplates.isOfficialApiMessageExpired]);

  const getMessages = async (sender, fromMsg, offset, limit) => {
    if (!sender || !fromMsg) {
      return [];
    }

    setIsMessagesLoading(true);
    setMessagesError(null);

    try {
      const response = await getRequest(
        `${baseUrl}/messages/${sender}/${fromMsg}/?limit=${limit}&offset=${offset}`,
      );

      const originalMessages = JSON.parse(JSON.stringify(response));

      setQuickAnswers({
        ...quickAnswers,
        messagesWithMediaUrl: [...messagesWithMediaUrl, ...originalMessages],
      });

      if (response.error) {
        setMessagesError(response);
        return [];
      }

      const quotedMessages = [];

      const quotedMessagePromises = originalMessages.map(async (msg) => {
        if (msg.mediaType === 'quotedMessage' && msg.quotedMsgId) {
          const quotedMessageResponse = await getRequest(
            `${baseUrl}/messages/${msg.quotedMsgId}`,
          );

          if (
            quotedMessageResponse.error
            && quotedMessageResponse.statusCode === 404
          ) {
            const quotedMessageWithError = {
              ...quotedMessageResponse,
              idMsg: msg.quotedMsgId,
            };

            setQuotedMessages((prevQuotedMessages) => [
              ...prevQuotedMessages,
              quotedMessageWithError,
            ]);
            return;
          }

          quotedMessages.push(quotedMessageResponse);
        }
      });

      await Promise.all(quotedMessagePromises);

      const quotedMessagesWithMedia = await fetchMediaUrls(quotedMessages);

      setQuotedMessages((prevQuotedMessages) => [
        ...prevQuotedMessages,
        ...quotedMessagesWithMedia,
      ]);

      const messagesWithMedia = await fetchMediaUrls(response);

      const decodedMessages = messagesWithMedia.map((msg) => {
        const updatedMsg = { ...msg };

        if (msg.body) {
          updatedMsg.body = fixEncodingStrings(msg.body);
        }

        if (msg.reacted) {
          updatedMsg.reacted = removeAfterSecondAmpersand(msg.reacted);
        }

        return updatedMsg;
      });

      const unreadMessages = decodedMessages.filter(
        (message) => !message.read && message.fromMe !== 1,
      );

      const updateReadPromises = unreadMessages.map(async (message) => {
        await patchRequest(`${baseUrl}/messages/updateToRead/${message.id}`);
      });

      await Promise.all(updateReadPromises);

      setHasMoreMessages(response.length === limit);

      return decodedMessages;
    } catch (error) {
      setErrorMessage(
        'Erro ao carregar mensagens! Entre em contato com o suporte.',
      );
      return [];
    } finally {
      setIsMessagesLoading(false);
    }
  };

  const fetchInternalMessages = async (currentChat, messagesPage) => {
    setIsEditingProfileGroupChat(false);

    try {
      const MESSAGE_PER_PAGE = 50;
      if (!currentChat) return;

      const response = await getRequest(
        `${baseUrl}/messages/internalMessages/${currentChat.id}?page=${messagesPage}&limit=${MESSAGE_PER_PAGE}`,
      );

      if (response.length > 0) {
        await patchRequest(
          `${baseUrl}/messages/updateInternalMessagesToRead/${user.id}/${currentChat.id}/${messages[0]?.id || response[0]?.id}`,
        );
      }

      const messagesWithQuotedMessages = response.filter(
        (message) => message.quotedMsgId,
      );

      messagesWithQuotedMessages.map(async (msg) => {
        if (msg.quotedMsgId) {
          const quotedMessageResponse = await getRequest(
            `${baseUrl}/messages/internalMessage/${msg.quotedMsgId}`,
          );

          setQuotedMessages((prevQuotedMessages) => [
            ...prevQuotedMessages,
            quotedMessageResponse,
          ]);
        }
      });

      setMessages((prevMessages) => {
        const isDifferentChat = prevMessages.some(
          (msg) => msg.chatId !== currentChat.id,
        );

        if (isDifferentChat) {
          setMessagesPage(1);
          return [...response];
        }
        return [...prevMessages, ...response];
      });

      setHasMoreMessages(response.length === MESSAGE_PER_PAGE);
      setOfficialApiTemplates((prevTemplates) => ({
        ...prevTemplates,
        isOfficialApiMessageExpired: false,
      }));
    } catch (error) {
      setErrorMessage(
        'Não foi possível carregar mensagens! Entre em contato com o suporte.',
      );
    }
  };

  const fetchMessages = async (currentChat, messagesPage) => {
    if (
      !currentChat
      || currentChat?.fromMsg !== previousFromMsgChat
      || currentChat?.sender !== previousSenderChat
    ) {
      setMessagesPage(1);
      setQuickAnswers({
        ...quickAnswers,
        messagesWithMediaUrl: [],
      });
      setQuotedMessages([]);
      setMessages([]);
      setSearchMessageValue('');
      setIsEditingProfileChat(false);
      setIsEditingProfileGroupChat(false);
      setPreviousSenderChat(currentChat?.sender);
      setPreviousFromMsgChat(currentChat?.fromMsg);
    }

    const fetchedMessages = await getMessages(
      currentChat?.sender,
      currentChat?.fromMsg,
      messagesPage === 1 ? 0 : messages.length,
      100,
    );

    setMessages((prevMessages) => {
      const uniqueMessages = fetchedMessages.filter(
        (newMsg) => !prevMessages.some((prevMsg) => prevMsg.id === newMsg.id),
      );

      const previousMessages = prevMessages.filter(
        (prevMsg) => !prevMsg.chatId,
      );
      return [...previousMessages, ...uniqueMessages];
    });
    if (messagesPage === 1) {
      setTimeout(handleScrollChatToBottom, 500);
    }
  };

  useEffect(() => {
    const fetchMessagesBasedOnChatType = async () => {
      if (messagesPage > 1 && currentChat?.is_internal_chat === 1) {
        fetchInternalMessages(currentChat, messagesPage);
      }
      if (messagesPage > 1 && !currentChat?.is_internal_chat) {
        fetchMessages(currentChat, messagesPage);
      }
    };
    fetchMessagesBasedOnChatType();
  }, [messagesPage, currentChat]);

  const sendMessage = async (
    textMessage,
    sender,
    phoneNumber,
    setTextMessage,
    mediaUrl,
  ) => {
    setIsMessagesLoading(true);

    if (textMessage === '' && !mediaUrl) return;

    const getMediaType = ({
      audioBlobURL,
      urlImageToSend,
      urlVideoToSend,
      urlDocumentToSend,
    }) => {
      if (audioBlobURL) {
        return 'audio/ogg; codecs=opus';
      }

      if (urlImageToSend.length > 0) {
        return 'image/jpeg';
      }

      if (urlVideoToSend.length > 0) {
        return 'video/mp4';
      }

      if (urlDocumentToSend.length > 0) {
        const isAudioFile = urlDocumentToSend.some(
          (file) => file.endsWith('.mp3')
            || file.endsWith('.ogg')
            || file.endsWith('.mpeg')
            || file.endsWith('.wav')
            || file.endsWith('.WAV'),
        );
        return isAudioFile ? 'audio/ogg; codecs=opus' : 'application/pdf';
      }

      return '';
    };

    const message = {
      body:
        user.showName === 1 ? `*${user.name}*:\n ${textMessage}` : textMessage,
      fromMe: 1,
      idMsg: null,
      isDeleted: null,
      sender,
      fromMsg: phoneNumber,
      createdAt: Date.now(),
      mediaType: getMediaType({
        audioBlobURL,
        urlImageToSend,
        urlVideoToSend,
        urlDocumentToSend,
      }),
      mediaUrl,
    };

    let responseMediaUrl = {};

    const token = user.senders[sender].sessionId;

    try {
      setTextMessage('');

      const updatedOpenTickets = updateUserChatsWithNewMessage(
        openTickets,
        message,
      );
      setOpenTickets(updatedOpenTickets);

      const updatedSortedChats = updateUserChatsWithNewMessage(
        sortedChats,
        message,
      );

      setSortedChats(updatedSortedChats);
      setShowNewChat(false);

      const textMessageWithName = user.showName === 1 ? `*${user.name}*:\n ${textMessage}` : textMessage;

      socket.emit(
        'sendedMessage',
        currentChat,
        message,
        user.ambiente,
        user.id,
      );

      setMessages((prev) => [message, ...prev]);
      setTimeout(handleScrollChatToBottom, 500);

      setQuickAnswers({
        ...quickAnswers,
        messagesWithMediaUrl: [message, ...messagesWithMediaUrl],
      });

      const response = await sendMessageRequest(
        token,
        textMessageWithName,
        phoneNumber,
        mediaUrl,
        user?.ambiente === 8890 ? sendMessageTestUrl : sendMessageUrl,
        // user?.id === 345 ? sendMessageTestUrl : sendMessageUrl,
        user?.id,
        currentChat?.id,
        currentChat?.setor_id,
      );

      if (response?.error) {
        switch (response?.message) {
          case 'Mensagem nao enviada':
            setErrorMessage(
              'Não foi possível enviar mensagem! Confira o status da sua conexão WhatsApp em https://interface.telein.com.br/  e tente novamente.\n\n Caso o erro persista, entre em contato com o suporte.',
            );
            break;

          case 'Error: no account exists':
            setErrorMessage(
              'Erro ao enviar mensagem. Cliente não possui WhatsApp.',
            );
            break;

          default:
            setErrorMessage(
              'Ocorreu um erro inesperado ao enviar mensagem. Entre em contato com o suporte.',
            );
        }
        return;
      }

      if (response?.mediaUrl) {
        responseMediaUrl = await getRequest(
          `${baseMediaUrl}${response.mediaUrl}`,
        );
      }

      if (response?.data?.key?.id || response?.messages[0].id) {
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((msg, index) => {
            if (index === 0) {
              return {
                ...msg,
                idMsg: response.data?.key?.id || response.messages[0].id,
                mediaUrl: response.mediaUrl ? responseMediaUrl.url : msg.mediaUrl,
              };
            }
            return msg;
          });

          return updatedMessages;
        });

        setQuickAnswers((prevQuickAnswers) => ({
          ...prevQuickAnswers,
          messagesWithMediaUrl: prevQuickAnswers.messagesWithMediaUrl.map(
            (msg, index) => (index === 0
              ? {
                ...msg,
                idMsg: response.data?.key?.id || response.messages[0].id,
                mediaUrl: response.mediaUrl ? responseMediaUrl.url : msg.mediaUrl,
              }
              : msg),
          ),
        }));
      }

      return response;
    } catch (error) {
      setErrorMessage(
        'Erro ao enviar mensagem. Entre em contato com o suporte!',
      );
    } finally {
      setIsMessagesLoading(false);
    }
  };

  const handleSendTemplateMessage = async () => {
    try {
      setIsMessagesLoading(true);

      if (!isAComment && !currentChat.is_internal_chat) {
        updateUserChatFromQueueToChats(currentChat);
      }

      const { sessionId } = user.senders[currentChat.sender];

      const payload = {
        token: sessionId,
        template_name: officialApiTemplates.selectedToSend.name,
        telefone: currentChat.fromMsg,
        language: officialApiTemplates.selectedToSend.language,
      };

      const response = await postRequest(`${sendTemplateMessageUrl}`, JSON.stringify(payload));

      const message = {
        body: officialApiTemplates?.selectedToSend?.components,
        fromMe: 1,
        idMsg: null,
        isDeleted: null,
        sender: currentChat.sender,
        fromMsg: currentChat.fromMsg,
        createdAt: Date.now(),
        mediaType: 'template',
        mediaUrl: '',
      };

      setMessages((prev) => [message, ...prev]);

      const updatedSortedChats = updateUserChatsWithNewMessage(
        sortedChats,
        message,
      );

      setSortedChats(updatedSortedChats);
      setShowNewChat(false);

      const messageWithId = {
        ...message,
        idMsg: response.messages[0].id,
      };

      socket.emit(
        'sendedMessage',
        currentChat,
        messageWithId,
        user.ambiente,
        user.id,
      );

      setTimeout(handleScrollChatToBottom, 500);

      if (response?.messages[0].id) {
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((msg, index) => {
            if (index === 0) {
              return {
                ...msg,
                idMsg: response.messages[0].id,
              };
            }
            return msg;
          });

          return updatedMessages;
        });
      }
    } catch (error) {
      setErrorMessage('Não foi possível enviar mensagem template. Entre em contato com o suporte');
    } finally {
      setIsMessagesLoading(false);
      setOfficialApiTemplates((prevTemplates) => ({
        ...prevTemplates,
        open: false,
        loading: false,
        selectedToSend: null,
        isOfficialApiTemplateSended: true,
      }));
    }
  };

  const handleInputMessageChange = (e) => {
    const { value } = e.target;
    setSearchMessageValue(value);
  };

  const markThisChatNotificationsAsRead = useCallback(
    (chat) => {
      const updatedChat = {
        ...chat,
        unreadCount: 0,
      };

      setSortedChats((prevSortedChats) => prevSortedChats.map((sortedChat) => {
        return sortedChat.fromMsg === updatedChat.fromMsg
            && sortedChat.sender === updatedChat.sender
          ? updatedChat
          : sortedChat;
      }));

      setUserChatsQueue((prevChatsQueue) => prevChatsQueue.map((chatQueue) => {
        return chatQueue.fromMsg === updatedChat.fromMsg
            && chatQueue.sender === updatedChat.sender
          ? updatedChat
          : chatQueue;
      }));

      setOpenTickets((prevOpenTickets) => prevOpenTickets.map((openTicket) => {
        return openTicket.fromMsg === updatedChat.fromMsg
            && openTicket.sender === updatedChat.sender
          ? updatedChat
          : openTicket;
      }));
    },
    [setSortedChats, setUserChatsQueue, setOpenTickets],
  );

  const handleFileUpload = async (
    files,
    uploadType,
    urlSetter,
    errorSetter,
    isLoadingSetter,
    progressSetter,
  ) => {
    if (files.length === 0) {
      return errorSetter(`Nenhum ${uploadType} foi selecionado para upload`);
    }

    const validateFile = (file) => {
      const validTypes = {
        video: 'video/',
        image: 'image/',
        audio: 'audio/',
        document: '',
      };

      if (uploadType === 'document') {
        return true;
      }

      if (!file.type.startsWith(validTypes[uploadType])) {
        errorSetter('Formato de arquivo inválido!');
        return false;
      }

      return true;
    };

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append(`${uploadType}s`, file);

      const config = {
        onUploadProgress: (percentCompleted) => {
          progressSetter(percentCompleted);
        },
      };

      if (currentChat.is_internal_chat === 1) {
        return postFormDataRequest(
          `${baseUrl}/messages/generate-presigned-${uploadType}-urls`,
          formData,
        );
      }

      return postFormDataRequest(
        `${baseUrl}/messages/upload-${uploadType}s`,
        formData,
        config,
      );
    };

    try {
      isLoadingSetter(true);
      progressSetter(0);

      const uploadPromises = Array.from(files)
        .filter(validateFile)
        .map(uploadFile);

      const responseData = await Promise.all(uploadPromises);

      const flattenedUrls = responseData.flatMap((data) => data);

      urlSetter(flattenedUrls);
    } catch (error) {
      errorSetter(
        `Não foi possível fazer upload do ${uploadType}! Entre em contato com o suporte.`,
      );
    } finally {
      isLoadingSetter(false);
      progressSetter(100);
    }
  };

  const imageUpload = useCallback(async () => {
    handleFileUpload(
      selectedImageToSend,
      'image',
      setUrlImageToSend,
      setErrorMessage,
      setIsMediaUploadLoading,
      setMediaUploadProgress,
    );
  }, [
    selectedImageToSend,
    setUrlImageToSend,
    setIsMediaUploadLoading,
    setErrorMessage,
    setMediaUploadProgress,
  ]);

  const videoUpload = useCallback(async () => {
    handleFileUpload(
      selectedVideoToSend,
      'video',
      setUrlVideoToSend,
      setErrorMessage,
      setIsMediaUploadLoading,
      setMediaUploadProgress,
    );
  }, [
    selectedVideoToSend,
    setUrlVideoToSend,
    setIsMediaUploadLoading,
    setErrorMessage,
    setMediaUploadProgress,
  ]);

  const documentUpload = useCallback(async () => {
    const disallowedExtensions = [
      'php',
      'js',
      'py',
      'rb',
      'java',
      'cpp',
      'c',
      'cs',
      'swift',
      'go',
      'kt',
      'ts',
      'sql',
      'sh',
      'pl',
      'lua',
      'r',
      'dart',
      'bash',
      'yaml',
      'ini',
      'toml',
      'exe',
    ];

    const hasInvalidFile = selectedDocumentToSend.some((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return disallowedExtensions.includes(fileExtension);
    });

    if (hasInvalidFile) {
      setErrorMessage('Tipo de arquivo não permitido!');
      return;
    }

    handleFileUpload(
      selectedDocumentToSend,
      'document',
      setUrlDocumentToSend,
      setErrorMessage,
      setIsMediaUploadLoading,
      setMediaUploadProgress,
    );
  }, [
    selectedDocumentToSend,
    setUrlDocumentToSend,
    setIsMediaUploadLoading,
    setErrorMessage,
    setMediaUploadProgress,
  ]);

  useEffect(() => {
    if (selectedImageToSend?.length > 0) {
      imageUpload();
    }

    if (selectedVideoToSend?.length > 0) {
      videoUpload();
    }

    if (selectedDocumentToSend?.length > 0 && selectedDocumentToSend[0].name) {
      documentUpload();
    }
  }, [selectedImageToSend, selectedVideoToSend, selectedDocumentToSend]);

  const cancelAudioRecording = () => {
    setAudioBlob(null);
    setAudioBlobURL(null);
    setIsRecordingAudio(false);
  };

  const handleShowAudioTranscription = async (mediaUrl, idMsg, body) => {
    const transcriptionExists = audioTranscription.transcriptionText.find(
      (transcription) => transcription.idMsg === idMsg,
    );

    if (transcriptionExists) {
      return setAudioTranscription((prevAudioTranscription) => ({
        ...prevAudioTranscription,
        transcriptionText: prevAudioTranscription.transcriptionText.map(
          (transcription) => (transcription.idMsg === idMsg
            ? {
              ...transcription,
              showAudioTranscription: !transcription.showAudioTranscription,
            }
            : transcription),
        ),
      }));
    }

    if (body !== '') {
      return setAudioTranscription((prevAudioTranscription) => ({
        ...prevAudioTranscription,
        transcriptionText: [
          ...prevAudioTranscription.transcriptionText,
          { idMsg, body, showAudioTranscription: true },
        ],
      }));
    }

    setAudioTranscription((prevAudioTranscription) => ({
      ...prevAudioTranscription,
      isLoadingTranscription: true,
    }));

    const formData = new FormData();
    formData.append('url', mediaUrl);

    try {
      const response = await postFormDataRequest(
        `${webhookTeleinUrl}/ia/audiototext_url.php`,
        formData,
      );

      const payload = {
        idMsg,
        body: response.text,
        showAudioTranscription: true,
      };

      await patchRequest(
        `${baseUrl}/messages/updateBodyById`,
        JSON.stringify({ idMsg, body: response.text }),
      );

      setAudioTranscription((prevAudioTranscription) => ({
        ...prevAudioTranscription,
        isLoadingTranscription: false,
        transcriptionText: [
          ...prevAudioTranscription.transcriptionText,
          payload,
        ],
      }));
    } catch (error) {
      setErrorMessage(
        'Não foi possível transcrever áudio! Entre em contato com o suporte',
      );

      setAudioTranscription((prevAudioTranscription) => ({
        ...prevAudioTranscription,
        isLoadingTranscription: false,
      }));
    }
  };

  return (
    <ChatContext.Provider
      value={{
        socket,
        allUsers,
        onlineUsers,
        setOnlineUsers,
        sessionConflict,
        setSessionConflict,
        isRecipientOnline,
        setMessages,
        getMessages,
        fetchMessages,
        fetchInternalMessages,
        updateInternalChatsWithNewMessage,
        quotedMessages,
        setQuotedMessages,
        messagesPage,
        containerRef,
        isInitialMount,
        handleScrollChatToBottom,
        setMessagesPage,
        hasMoreMessages,
        showNewChat,
        setShowNewChat,
        initialParamsForNewChat,
        setInitialParamsForNewChat,
        userChats,
        departments,
        selectedDepartment,
        selectedAttendant,
        selectedSenderKey,
        setSelectedSenderKey,
        setSelectedDepartment,
        setSelectedAttendant,
        getUserChats,
        setUserChats,
        sortedChats,
        setSortedChats,
        userChatsQueue,
        getUserChatsQueue,
        setUserChatsQueue,
        openTickets,
        getOpenTickets,
        setOpenTickets,
        internalChats,
        setInternalChats,
        archivedInternalChats,
        setArchivedInternalChats,
        openArchivedInternalChats,
        setOpenArchivedInternalChats,
        unreadInternalMessagesCount,
        chatTags,
        setChatTags,
        isTicketTransferred,
        setIsTicketTransferred,
        openTransferModal,
        setOpenTransferModal,
        updateTicketToClosed,
        updateUserChatsWithNewMessage,
        handleTransferTicket,
        handleCreateTicketClosedNotification,
        handleSendTemplateMessage,
        activeSectionChats,
        setActiveSectionChats,
        sortUserChatsByRecentMessages,
        updateUserChatFromQueueToChats,
        chatMarkedAsUnread,
        setChatMarkedAsUnread,
        handleInputMessageChange,
        handleSetIsOfficialApiTemplateExpired,
        handleSetIsOfficialApiTemplateSended,
        scheduledMessages,
        setScheduledMessages,
        messageToScroll,
        setMessageToScroll,
        isAComment,
        setIsAComment,
        userChatsError,
        isUserChatsLoading,
        setIsUserChatsLoading,
        isOpenTicketsLoading,
        isMediaUploadLoading,
        setIsMediaUploadLoading,
        mediaUploadProgress,
        fetchMediaUrls,
        selectedImageToSend,
        setSelectedImageToSend,
        urlImageToSend,
        setUrlImageToSend,
        urlVideoToSend,
        setUrlVideoToSend,
        selectedVideoToSend,
        setSelectedVideoToSend,
        selectedDocumentToSend,
        setSelectedDocumentToSend,
        urlDocumentToSend,
        setUrlDocumentToSend,
        currentChat,
        setCurrentChat,
        isEditingProfile,
        setIsEditingProfile,
        isEditingProfileChat,
        setIsEditingProfileChat,
        isEditingProfileGroupChat,
        setIsEditingProfileGroupChat,
        officialApiTemplates,
        setOfficialApiTemplates,
        messages,
        searchMessageValue,
        isMessagesLoading,
        messagesError,
        sendMessage,
        markThisChatNotificationsAsRead,
        audioBlob,
        setAudioBlob,
        audioBlobURL,
        setAudioBlobURL,
        isRecordingAudio,
        setIsRecordingAudio,
        isUploadingAudioMessage,
        setIsUploadingAudioMessage,
        cancelAudioRecording,
        audioTranscription,
        handleShowAudioTranscription,
        alert,
        hideAlert,
        showAlert,
        quickAnswers,
        setQuickAnswers,
        kanban,
        setKanban,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
