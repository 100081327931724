import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
    border-radius: 20px;
    }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.2);
  }

  .fullwidth-inputs-selects {
    text-align: left;
    margin-top: 10px;
    height: 30%;
    width: 100%;
    border-radius: 6px;
    padding: 6px;
    font-size: 16px;
    overflow: auto;
    border: 1px solid #AEBAC1;
    resize: vertical;
  }

  .instruction-span {
    font-size: 12px;
  }

  .add-button {
    width: 30%;
    color: ${({ theme }) => theme.colors.text.secondary};
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 5px;
    border-radius: 6px;
    font-size: 16px;
  }

  .template-button-div {
    display: flex;
    align-items: center;
    gap: 5px;

    input, select {
    border-radius: 6px;
    padding: 6px;
    font-size: 16px;
    overflow: auto;
    border: 1px solid #AEBAC1;
    resize: vertical;
    }

    button {
      border-radius: 50%;
      padding: 5px;
      background-color: transparent;
      transition: 0.2s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.buttons.hover};
      }

      svg {
        color: ${({ theme }) => theme.colors.icons.default}
      }
    }
  }

`;
