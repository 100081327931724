import styled from 'styled-components';
import bgImgChat from '../../assets/images/chat-bg2.png';

export const Container = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
width: 100%;

.save-edit-button {
  margin-right: 15px;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: 0.2s ease-in;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 5px;

  svg {
    color: ${({ theme }) => theme.colors.buttons.background};
    font-size: 28px;
  }

  &:hover {
    filter: brightness(1.3);
  }
}
`;

export const ContainerHeader = styled.header`
flex-shrink: 0;

img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 15px 0 15px;
}
`;

export const ContainerChat = styled.main`
flex-grow: 1;
background-image: url(${bgImgChat});
overflow-y: auto;
padding: 10px 15px;
position: relative;

&::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

&::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0,0,0,0.2);
}
`;

export const ContainerFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borders.secondary};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: 15px;
  width: 100%;
  max-width: 100%;
  height: auto;

  .messages-input-container {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.backgroundColor};
    flex: 1;
  }

  .messages-input-container textarea {
    background-color: ${({ theme }) => theme.colors.buttons.background};
    border: none;
    font-size: 16px;
    border-radius: 8px;
    height: auto;
    width: 100%;
    max-width: 90%;
    align-self: start;
    margin: 0px 20px 0px 20px;
    padding: 8px 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    line-height: 20px;
    max-height: 200px;
    resize: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0,0,0,0.2);
    }
  }

  .no-input-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    font-size: 20px;
    transition: 0.2s ease-out;

    button {
      margin-left: 10px;
      font-size: 14px;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid black;
      }
    }

    .select-template-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.background.sidebar};
      padding: 5px;
      transition: 0.2s;

      &:hover {
        filter: brightness(1.1);
      }

      svg {
        font-size: 30px;
      }
    }
  }

  .emojis-button {
    color: ${({ theme }) => theme.colors.icons.default};
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-recorder {
    margin: 0 20px 0 10px;
  }

  .hidden {
    display: none;
  }

  .messages-input-container .start-recording-button,
  .messages-input-container .send-message-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    align-self: center;
    height: 35px;
    margin-right: 30px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 50%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    transition: 0.2s ease-in;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .selected-template-preview {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin: 5px 0;
    background-color: ${({ theme }) => theme.colors.extras.blueTelein};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: 10px;
    transition: 0.2s;
    font-size: 14px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: end;
      padding: 5px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.secondary};

      &:hover {
        filter: brightness(1.1);
      }

      svg {
        color: ${({ theme }) => theme.colors.icons.chatMessage}
      }
    }
  }
`;

export const QuickAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-width: 400px;
    width: 350px;
    height: auto;

    h3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .add-new-quick-answer-div {
      padding: 10px 0 10px 0;
    }

    .back-to-main-quick-answers-button {
      background-color: transparent;
      transition: 0.2s;
      border-radius: 50%;

      &:hover {
        filter: brightness(0.9);
      }
    }

    input {
      text-align: left;
      margin-top: 10px;
      height: 30%;
      width: 100%;
      padding: 6px;
      font-size: 16px;
      overflow: auto;
      border: 1px solid ${({ theme }) => theme.colors.borders.third};
      resize: vertical;
    }

    textarea {
      text-align: left;
      margin-top: 10px;
      height: 300px;
      width: 100%;
      padding: 6px;
      font-size: 16px;
      overflow: auto;
      border: 1px solid #AEBAC1;
      resize: vertical;
    }

    .quick-answers-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0 0 0;

      button {
        padding: 5px;
        border-radius: 10px;
        margin-left: 10px;
        background-color: ${({ theme }) => theme.colors.background.main};

        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    .search-quick-answers-input {
      border-radius: 6px;
      margin-bottom: 10px;

    }

    .answers-wrapper {
      margin: 0 0 10px 0;
      width: 100%;
      height: 300px;
      overflow-y: auto;
      padding-right: 10px;
      border-radius: 6px;

      &::-webkit-scrollbar {
        width: 10px;
        height: 6px;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: rgba(0,0,0,0.2);
      }
    }

    .add-quick-answers-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      align-self: center;
      background-color: ${({ theme }) => theme.colors.secondary};
      border-radius: 50%;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      transition: 0.2s ease-in;

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
        filter: brightness(0.8);
      }
    }

    .quick-answers-button {
      width: 100%;
      max-height: 200px;
      height: 80px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

      p {
        width: 100%;
        height: 100%;
        padding: 0 10px 10px 0;
        margin-top: 5px;
        display: flex;
        align-items: start;
        justify-content: start;
        text-overflow: ellipsis;
        text-align: left;
        overflow-x: visible;
        overflow-y: hidden;
        white-space: normal;
        word-break: break-word;
      }
    }

    .quick-answers-wrapper-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }

    .delete-quick-answer-button {
      top: 0;
      right: 0;
      height: 10px;
      transition: 0.2s;
      font-size: 15px;
      background-color: transparent;

      &:hover {
        font-weight: 700;

      }
    }
`;

export const NewQuickAnswerInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #BBB;
  border-radius: 4px;
`;

export const TemplateRendererContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  div {
    overflow-y: auto;
  }

    &::-webkit-scrollbar {
      width: 10px;
      height: 6px;
      border-radius: 20px;
      }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.3);
    }

  .select-template-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .select-template-button {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin: 5px 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: 10px;
    transition: 0.2s;

    &:hover {
      filter: brightness(1.1);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    background-color: ${({ theme }) => theme.colors.extras.blueTelein};

    &.pending {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    &.rejected {
      background-color: ${({ theme }) => theme.colors.buttons.danger};
    }
  }

  .add-new-template-button {
    position: sticky;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: 10px;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
      filter: brightness(1.1);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }

  .load-template-wrapper {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SelectedTemplatePreview = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin: 20px 0;
    background-color: ${({ theme }) => theme.colors.extras.blueTelein};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: 10px;
    transition: 0.2s;
    font-size: 14px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: end;
      margin-top: 10px;
      padding: 2px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.secondary};

      &:hover {
        filter: brightness(1.1);
      }

      svg {
        color: ${({ theme }) => theme.colors.icons.chatMessage}
      }
    }
`;
