import Linkify from 'linkify-react';
import React from 'react';

export const baseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_BASE_URL
  : 'https://chat.telein.com.br/api';

export const webhookBaseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_SERVER_WEBHOOK_URL
  : 'https://chat.telein.com.br/webhook';

export const webhookTeleinUrl = 'https://chat.telein.com.br/webhookTeleinIA';

export const sendTemplateMessageUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/enviarTemplate.php';

export const getTemplatesBaseUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/listarTemplates.php';

export const baseMediaUrl = 'https://chat.telein.com.br/proxy/panfletointeligente/webhook/assinar_s3.php?mediaUrl=';

export const deleteMessageUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/deleteMensagem.php';

export const sendMessageUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/enviarMensagem.php';

export const sendMessageTestUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/enviarMensagem_gomes.php';

export const countryCodeToHtmlEmoji = {
  BR: '&#127463;&#127479;',
  US: '&#127482;&#127480;',
  ES: '&#127466;&#127480;',
  FR: '&#127467;&#127479;',
  DE: '&#127465;&#127466;',
  IT: '&#127470;&#127481;',
  JP: '&#127471;&#127477;',
  CN: '&#127464;&#127475;',
  RU: '&#127479;&#127482;',
  IN: '&#127470;&#127475;',
  GB: '&#127468;&#127463;',
  CA: '&#127464;&#127462;',
  AU: '&#127462;&#127482;',
  KR: '&#127472;&#127479;',
  MX: '&#127474;&#127485;',
  AR: '&#127462;&#127479;',
};

const uploadWithProgress = async (url, formData, onProgress) => {
  const xhr = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        onProgress(percentCompleted);
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(new Error(`Request failed with status ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error('Network error'));
    };

    xhr.open('POST', url, true);
    xhr.send(formData);
  });
};

export const postRequest = async (url, body) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });

  const data = await response.json();

  if (!response.ok) {
    let message;

    if (data?.message) {
      message = data.message;
    } else {
      message = data;
    }

    return { error: true, message };
  }

  return data;
};

export const sendMessageRequest = async (
  token,
  message,
  phoneNumber,
  mediaUrl,
  sendMessageUrl,
  userId,
  chatId,
  setorId,
) => {
  const body = {
    token,
    mensagem: message,
    telefone: phoneNumber,
    // sendSocket: 1,
    ticketId: chatId,
    userId,
    setorId,
    chat_telein: true,
    ...(mediaUrl && { url: mediaUrl }),
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  try {
    const response = await fetch(sendMessageUrl, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      const message = data?.message || data;
      return { error: true, message };
    }

    return data;
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const postFormDataRequest = async (url, formData, config = {}) => {
  try {
    let responseData;

    if (config.onUploadProgress) {
      responseData = await uploadWithProgress(url, formData, config.onUploadProgress);
    } else {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        ...config,
      });

      responseData = await response.json();

      if (!response.ok) {
        let message;

        if (responseData?.message) {
          message = responseData.message;
        } else {
          message = responseData;
        }

        return { error: true, message };
      }
    }

    return responseData;
  } catch (error) {
    console.error('Error in postFormDataRequest:', error);
    return { error: true, message: 'Network error or server unavailable' };
  }
};

export const getRequest = async (url) => {
  const response = await fetch(url);

  const data = await response.json();

  if (!response.ok) {
    let message = 'An error occured!';

    if (data?.message) {
      message = data.message;
    }

    return { error: true, message, statusCode: response.status };
  }

  return data;
};

export const patchRequest = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Error making PATCH request: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error('Error making PATCH request:', error);
  }
};

export const deleteRequest = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error making DELETE request: ${response.statusText}`);
    }

    if (response.status === 204) {
      return null;
    }

    return await response.json();
  } catch (error) {
    throw new Error(`Error making DELETE request: ${error.message}`);
  }
};

export const formatPhoneNumber = (phone) => {
  if (!phone) return;

  const cleanPhone = phone.split('@')[0];

  const hasPlus = cleanPhone.startsWith('+');
  const normalizedPhone = hasPlus ? cleanPhone.slice(1) : cleanPhone;

  const countryCode = normalizedPhone.slice(0, 2);
  const areaCode = normalizedPhone.slice(2, 4);
  const numberPart1 = normalizedPhone.slice(4, 8);
  const numberPart2 = normalizedPhone.slice(8);

  return `${hasPlus ? '+' : ''}${countryCode} (${areaCode}) ${numberPart1}-${numberPart2}`;
};

export const fixEncodingStrings = (str) => {
  const isMalformed = /�|Ã|&[a-z]+;/.test(str);

  if (isMalformed) {
    const bytes = new Uint8Array([...str].map((char) => char.charCodeAt(0)));
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(bytes);
  }

  return str;
};

export const removeAfterSecondAmpersand = (str) => {
  const firstAmpersandIndex = str.indexOf('&');
  const secondAmpersandIndex = str.indexOf('&', firstAmpersandIndex + 1);

  if (firstAmpersandIndex !== -1 && secondAmpersandIndex !== -1) {
    return str.slice(0, secondAmpersandIndex);
  }

  return str;
};

export const getSenderName = (fromMe, currentChat) => {
  if (fromMe === 0) {
    return currentChat.customName
    || currentChat.nome
    || formatPhoneNumber(currentChat.fromMsg);
  }
};

export const formatContactCardBody = (body, mediaType) => {
  if (mediaType === 'contactMessage') {
    const nameMatch = body.match(/FN:([^;]*);/);
    const contactName = nameMatch ? nameMatch[1].trim() : '';
    const phoneMatches = [...body.matchAll(/waid=(\d+):/g)];

    return (
      <>
        <h1>{contactName}</h1>
        {phoneMatches.map((match, index) => (
          <span key={index}>
            {formatPhoneNumber(match[1])}
          </span>
        ))}
      </>
    );
  }
  return null;
};

export const formatMessageBody = (
  message,
  isDeleted = null,
  isInternalChat,
  highlightString = '',
) => {
  const regex = /^\*(.*?)\*:\s*/;
  const match = message.match(regex);

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener noreferrer',
    className: 'custom-linkify-link',
  };

  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const highlightText = (text, highlight) => {
    if (!highlight) return formatTextWithLineBreaks(text);

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: '#FFCC22', fontSize: '16px' }}>{part}</span>
    ) : (
      <React.Fragment key={index}>{part}</React.Fragment>
    )));
  };

  const messageStyle = {
    textDecoration: isDeleted ? 'line-through' : 'none',
  };

  if (match) {
    const boldText = match[1];
    const remainingText = message.replace(regex, '');

    return (
      <>
        <i><strong>{`${boldText}:`}</strong></i>
        {((!isDeleted && !isInternalChat)
          || (isInternalChat && !isDeleted)
          || !isInternalChat) && (
          <p style={messageStyle}>
            <Linkify options={linkifyOptions}>
              {highlightText(remainingText, highlightString)}
            </Linkify>
          </p>
        )}
      </>
    );
  }

  return (
    <p style={messageStyle}>
      <Linkify options={linkifyOptions}>
        {highlightText(message, highlightString)}
      </Linkify>
    </p>
  );
};

export const stripPrefix = (idWithPrefix) => {
  const separatorIndex = idWithPrefix.indexOf('-');
  if (separatorIndex !== -1) {
    return idWithPrefix.slice(separatorIndex + 1);
  }
  return idWithPrefix;
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const ItemTypes = {
  CARD: 'CARD',
  CHAT: 'CHAT',
};

export const requestNotificationPermission = async () => {
  if (!('Notification' in window)) {
    return false;
  }

  if (Notification.permission === 'granted') {
    return true;
  }

  const permission = await Notification.requestPermission();
  return permission === 'granted';
};

export const showNotification = (title, options) => {
  if (Notification.permission === 'granted') {
    const notification = new Notification(title, options);

    notification.onclick = () => {
      window.focus();

      if (window.parent) {
        window.parent.focus();
      }

      notification.close();

      if (options.onClick) {
        options.onClick();
      }
    };
  }
};
