import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import CircularProgress from '@mui/material/CircularProgress';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from 'styled-components';
import { Container } from './styles';
import { useErrors } from '../../context/ErrorContext';
import { ChatContext } from '../../context/ChatContext';
import { baseUrl, postFormDataRequest } from '../../utils/services';

export default function AudioRecorder() {
  const {
    currentChat,
    audioBlob,
    setAudioBlob,
    audioBlobURL,
    setAudioBlobURL,
    isRecordingAudio,
    setIsRecordingAudio,
    isUploadingAudioMessage,
    setIsUploadingAudioMessage,
    cancelAudioRecording,
  } = useContext(ChatContext);

  const theme = useTheme();

  const [recordingTime, setRecordingTime] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const timerInterval = useRef(null);
  const audioContext = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaStream = useRef(null);
  const audioChunks = useRef([]);

  const { setErrorMessage } = useErrors();

  const resetMediaRecorder = () => {
    mediaRecorder.current = null;
    audioContext.current?.close();
    audioContext.current = null;
    mediaStream.current = null;
  };

  const handleStartRecording = async () => {
    setIsRecordingAudio(true);
    setRecordingTime(0);

    try {
      audioChunks.current = [];
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(audioChunks.current, { type: 'audio/webm' });
        setAudioBlob(blob);
        setIsRecordingAudio(false);
        clearInterval(timerInterval.current);
        resetMediaRecorder();
      };
    } catch (error) {
      setIsRecordingAudio(false);
      setErrorMessage('Algo deu errado! Entre em contato com o suporte.');
    }

    mediaRecorder?.current?.start();

    timerInterval.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const pauseRecording = () => {
    if (isPaused) {
      mediaRecorder.current.resume();
      setIsPaused(false);
      timerInterval.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      mediaRecorder.current.pause();
      setIsPaused(true);
      clearInterval(timerInterval.current);
    }
  };

  const handleStopRecording = () => {
    mediaRecorder.current.stop();
    setIsPaused(false);
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleCancelAudioRecording = async () => {
    cancelAudioRecording();
    setRecordingTime(0);
    clearInterval(timerInterval.current);
    mediaStream.current?.getTracks().forEach((track) => track.stop());
  };

  useEffect(() => {
    const uploadAudio = async () => {
      if (audioBlob) {
        setIsUploadingAudioMessage(true);
        try {
          const formData = new FormData();
          formData.append('audio', audioBlob);

          if (currentChat?.is_internal_chat === 1) {
            const response = await postFormDataRequest(`${baseUrl}/messages/generate-presigned-audio-url`, formData);

            setAudioBlobURL(response);
            return;
          }

          const response = await postFormDataRequest(`${baseUrl}/messages/upload-audio`, formData);

          setAudioBlobURL(response);
        } catch (error) {
          setErrorMessage('Áudio não carregado! Entre em contato com o suporte.');
          handleCancelAudioRecording();
        } finally {
          setIsUploadingAudioMessage(false);
        }
      }
    };
    uploadAudio();
  }, [audioBlob,
    setAudioBlobURL]);

  return isUploadingAudioMessage ? (
    <div
      style={{
        marginRight: '20px',
      }}
    >
      <CircularProgress
        variant="indeterminate"
      />
    </div>
  )
    : (
      <Container>
        {audioBlobURL ? (
          <>
            <audio controls>
              <source
                src={audioBlobURL.apacheUrl
                  ? audioBlobURL.apacheUrl
                  : audioBlobURL}
                type="audio/webm"
              />
              <track kind="captions" src="" />
            </audio>
            <button
              className="cancel-audio-button"
              type="button"
              onClick={handleCancelAudioRecording}
              aria-label="Cancel audio"
            >
              <DeleteIcon style={{
                color: theme.colors.icons.default,
                fontSize: '25px',
              }}
              />
            </button>
          </>
        )
          : (
            <>
              {isRecordingAudio && mediaStream.current && (
              <>
                <div className="audio-visualizer-box">
                  <p className="audio-timer">
                    {formatTime(recordingTime)}
                  </p>
                  <LiveAudioVisualizer
                    smoothingTimeConstant={0.5}
                    mediaRecorder={mediaRecorder.current}
                    maxDecibels={-20}
                    minDecibels={-80}
                    width={150}
                    height={30}
                    barColor="#014F6D"
                  />

                </div>
                <button
                  type="button"
                  aria-label="pausar e retomar a gravação do áudio"
                  onClick={pauseRecording}
                >
                  {
                    isPaused ? (
                      <PlayArrowIcon style={{
                        color: '#F5F5F5',
                        fontSize: '25px',
                      }}
                      />
                    )
                      : (
                        <PauseIcon style={{
                          color: '#F5F5F5',
                          fontSize: '25px',
                        }}
                        />
                      )
                  }
                </button>
              </>
              )}

              <button
                type="button"
                onClick={
              isRecordingAudio
                ? handleStopRecording
                : handleStartRecording
            }
                className={
              isRecordingAudio
                ? 'recording-audio-button'
                : ''
            }
                aria-label="Record/Stop audio"
              >
                {isRecordingAudio
                  ? (
                    <StopIcon style={{ color: '#F5F5F5', fontSize: '25px' }} />
                  )
                  : (
                    <MicIcon style={{ color: '#F5F5F5', fontSize: '25px' }} />
                  )}
              </button>
            </>
          )}
      </Container>
    );
}
