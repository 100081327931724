import PropTypes from 'prop-types';
import he from 'he';
import { Container } from './styles';

export default function TemplateRenderer({
  message,
}) {
  const getTemplateData = () => {
    if (Array.isArray(message)) return message;

    const escapedMessage = message
      .replace(/\r/g, '\\r')
      .replace(/\n/g, '\\n');

    const parsedTemplate = JSON.parse(he.decode(escapedMessage));

    return parsedTemplate;
  };

  const templateData = getTemplateData();

  return (
    <Container>
      {templateData.map((item, index) => {
        switch (item.type) {
          case 'HEADER':
            return <h1 key={index}>{item.text}</h1>;
          case 'BODY':
            return <p key={index}>{he.decode(item.text)}</p>;
          case 'FOOTER':
            return <footer key={index}>{item.text}</footer>;
          case 'BUTTONS':
            return (
              <div
                className="buttons-container"
                key={index}
              >
                {item.buttons.map((button, buttonIndex) => (
                  <span key={buttonIndex}>
                    {button.text}
                  </span>
                ))}
              </div>
            );
          default:
            return null;
        }
      })}
    </Container>
  );
}

TemplateRenderer.propTypes = {
  message: PropTypes.string.isRequired,
};
